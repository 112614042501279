import { useEmailSchema } from '@/common/hooks/schemas/useEmailSchema'
import { useNameSchema } from '@/common/hooks/schemas/useNameSchema'
import { useMemo } from 'react'
import * as yup from 'yup'

export const useAgencyMemberInfoSchema = () => {
  const { schema: nameSchema } = useNameSchema()
  const { schema: emailSchema } = useEmailSchema()

  const schema = useMemo(
    () =>
      yup.object().shape({
        firstName: nameSchema.required(),
        lastName: nameSchema.required(),
        email: emailSchema.required(),
      }),
    [emailSchema, nameSchema]
  )

  return { schema }
}

export type MemberInfoFormValues = yup.InferType<
  ReturnType<typeof useAgencyMemberInfoSchema>['schema']
>
