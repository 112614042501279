import {
  MemberInfoFormValues,
  useAgencyMemberInfoSchema,
} from '@/agencyMember/hooks/useAgencyMemberInfoSchema'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface AdminAddFormProps {
  onClick: (values: MemberInfoFormValues) => Promise<void>
  loading: boolean
}

export const AdminAddForm = ({ loading, onClick }: AdminAddFormProps) => {
  const { t } = useTranslation()
  const { schema } = useAgencyMemberInfoSchema()

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
  }

  const form = useForm<MemberInfoFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onSubmit = async (values: MemberInfoFormValues) => {
    await onClick(values)
    form.reset(defaultValues)
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="tw-space-y-5">
          <FormField
            control={form.control}
            name="firstName"
            disabled={loading}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('UserInfoForm.firstName')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            disabled={loading}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('UserInfoForm.lastName')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            disabled={loading}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('UserInfoForm.email')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" disabled={loading}>
            {t('UserInfoForm.actions.add')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
