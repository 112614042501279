import React, { useEffect, useState } from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { BuyerMemberFolder } from './BuyerMemberFolder'
import { useTranslation } from 'react-i18next'
import { BuyerMemberFolderFragment } from '@gql'

export type BuyerMemberFoldersProps = {
  buyerMemberFolders: BuyerMemberFolderFragment[]
}
export const BuyerMemberFolders = ({
  buyerMemberFolders,
}: BuyerMemberFoldersProps) => {
  const { t } = useTranslation()

  const [value, _setValue] = useState('addBuyer')
  const setValue = (id?: string) => {
    _setValue(id || 'addBuyer')
  }
  useEffect(() => {
    if (!value || value === 'addBuyer') {
      setValue(buyerMemberFolders[0]?.id)
    }
  }, [buyerMemberFolders, value])

  const getBuyerMemberFolderLabel = (index: number, isVarious?: boolean) => {
    if (isVarious) {
      return t('BuyerMemberFolders.coBuyer', { number: index + 1 })
    }
    return t('BuyerMemberFolders.buyer')
  }

  if (!buyerMemberFolders.length) return t('BuyerMemberFolders.noFolders')

  return (
    <Tabs value={value} onValueChange={setValue} className="tw-w-full">
      <TabsList className="tw-h-auto tw-max-h-[100px] tw-flex-wrap tw-overflow-auto tw-gap-2 tw-justify-stretch">
        {buyerMemberFolders.map((buyerMemberFolder, index) => (
          <TabsTrigger
            key={buyerMemberFolder.id}
            value={`${buyerMemberFolder.id}`}
          >
            {getBuyerMemberFolderLabel(index, buyerMemberFolders.length > 1)}
          </TabsTrigger>
        ))}
      </TabsList>
      {buyerMemberFolders.map((buyerMemberFolder) => (
        <TabsContent
          key={buyerMemberFolder.id}
          value={`${buyerMemberFolder.id}`}
          forceMount={true} // Required to have a smooth experience on controlled component on NextJS
          className={value === buyerMemberFolder.id ? '' : 'tw-hidden'} // Required to have a smooth experience on controlled component on NextJS
        >
          <BuyerMemberFolder {...buyerMemberFolder} />
        </TabsContent>
      ))}
    </Tabs>
  )
}
