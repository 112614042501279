import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { ConfirmationDialog } from '@/components/ui/ConfirmationDialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useToast } from '@/components/ui/use-toast'
import { AgencyMemberFragment, useDeleteUserMutation } from '@gql'
import { useUserData } from '@nhost/react'
import { getInitials } from '@utils/getInitials'
import { Ellipsis, Mails, Trash } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { trpc } from 'src/trpc'

export default function AdminItem(agencyMember: AgencyMemberFragment) {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [confirmationPendingCallback, setConfirmationPendingCallback] =
    useState<() => Promise<void> | void>()
  const currentMember = useUserData()
  const [deleteUser] = useDeleteUserMutation()

  const handleResendInvite = async () => {
    try {
      if (!agencyMember.user?.email && !agencyMember.inviteEmail) {
        throw new Error('Email is required')
      }
      await trpc.agencyMember.inviteAgencyMember.mutate({
        agencyId: agencyMember.agencyId,
        firstName: agencyMember.user?.metadata?.firstName ?? '',
        lastName: agencyMember.user?.metadata?.lastName ?? '',
        email: agencyMember.user?.email ?? agencyMember.inviteEmail!,
      })
      toast({
        title: t('AdminItem.inviteAgencyMember.success.title', {
          agencyMember: agencyMember.user?.displayName,
        }),
        description: t('AdminItem.inviteAgencyMember.success.description'),
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      toast({
        title: t('AdminItem.inviteAgencyMember.error.title'),
        description: t('AdminItem.inviteAgencyMember.error.description'),
        variant: 'destructive',
      })
    }
  }

  const handleDelete = async () => {
    if (!agencyMember.userId) return
    await deleteUser({
      variables: {
        id: agencyMember.userId,
      },
      onCompleted: () => {
        toast({
          title: t('AdminItem.deleteUser.success.title'),
          description: t('AdminItem.deleteUser.success.description'),
          variant: 'success',
        })
      },
      onError: (error) => {
        console.error(error)
        toast({
          title: t('AdminItem.deleteUser.error.title'),
          description: t('AdminItem.deleteUser.error.description'),
          variant: 'destructive',
        })
      },
    })
  }

  return (
    <div className="tw-p-2 tw-flex tw-items-center">
      <div className="tw-flex tw-items-center tw-flex-grow">
        <Avatar className="tw-flex tw-h-8 tw-w-8 tw-border">
          <AvatarFallback>
            {getInitials(
              agencyMember.user?.displayName ?? agencyMember.user?.email ?? ''
            )}
          </AvatarFallback>
        </Avatar>
        <div className="tw-ml-4">
          <p className="tw-font-medium">{agencyMember.user?.displayName}</p>
          <p>{agencyMember.user?.email}</p>
        </div>
      </div>
      <div className="tw-space-x-2">
        {!agencyMember.userId && <Badge>{t('common.badge.invited')}</Badge>}
        <Badge variant="secondary">{t('common.badge.agencyMember')}</Badge>
      </div>
      {agencyMember.userId !== currentMember?.id && (
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="tw-ml-2">
              <Ellipsis />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {!agencyMember.hasSetPassword && (
              <>
                <DropdownMenuItem
                  className="tw-flex tw-items-center tw-gap-2"
                  onClick={handleResendInvite}
                >
                  <Mails size="16" />
                  {t('MemberItem.actions.resendInvite')}
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="tw-flex tw-items-center tw-gap-2"
                  onClick={() =>
                    setConfirmationPendingCallback(() => handleDelete)
                  }
                >
                  <Trash size="16" />
                  {t('MemberItem.actions.revokeInvite')}
                </DropdownMenuItem>
              </>
            )}
            {agencyMember.hasSetPassword && (
              <DropdownMenuItem
                className="tw-flex tw-items-center tw-gap-2"
                onClick={() =>
                  setConfirmationPendingCallback(() => handleDelete)
                }
              >
                <Trash size="16" />
                {t('MemberItem.actions.delete')}
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
      <ConfirmationDialog
        open={!!confirmationPendingCallback}
        onOpenChange={() => setConfirmationPendingCallback(undefined)}
        onConfirm={confirmationPendingCallback}
      />
    </div>
  )
}
