import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
} from '@/components/ui/form'

import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Switch } from '@/components/ui/switch'
import { EstateUpdateSchema } from '@/estate/hooks/useUpdateEstateSchema'
import { useFormContext } from 'react-hook-form'

export const EstatePublicationForm = () => {
  const { t } = useTranslation()
  const { control } = useFormContext<EstateUpdateSchema>()

  return (
    <div className="tw-grid tw-grid-cols-6 tw-gap-4 tw-items-end">
      <FormField
        control={control}
        name="publicationTitle"
        render={({ field }) => (
          <FormItem className="tw-col-span-6">
            <FormLabel>{t(`EstatePublicationForm.publicationTitle`)}</FormLabel>
            <FormControl>
              <Input
                {...field}
                value={field.value === null ? '' : field.value}
              />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="publicationDescription"
        render={({ field }) => (
          <FormItem className="tw-col-span-6">
            <FormLabel>
              {t(`EstatePublicationForm.publicationDescription`)}
            </FormLabel>
            <FormControl>
              <Textarea
                {...field}
                value={field.value === null ? '' : field.value}
              />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="publicationMention"
        render={({ field }) => (
          <FormItem className="tw-col-span-6">
            <FormLabel>
              {t(`EstatePublicationForm.publicationMention`)}
            </FormLabel>
            <FormControl>
              <Input
                {...field}
                value={field.value === null ? '' : field.value}
              />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="canConnectedBuyerSeeAddress"
        render={({ field }) => (
          <FormItem className="tw-col-span-6">
            <FormControl>
              <Switch checked={field.value} onCheckedChange={field.onChange} />
            </FormControl>
            <FormLabel>
              {t(`EstatePublicationForm.canConnectedBuyerSeeAddress`)}
            </FormLabel>
          </FormItem>
        )}
      />
    </div>
  )
}
