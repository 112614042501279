import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Estate_Type_Enum } from '@gql'
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
} from '@/components/ui/form'
import { ConditionRating } from './ConditionRating'
import { ServiceRating } from './ServiceRating'
import {
  EstateServicesAndConditions,
  servicesAndConditionsVisibilityMatrix,
} from '@almaris/shared/model/estate'
import { useFormContext } from 'react-hook-form'
import { EstateUpdateSchema } from '@/estate/hooks/useUpdateEstateSchema'

export type EstateConditionAndServicesFormProps = {
  estateType: Estate_Type_Enum | null
}

export const EstateConditionAndServicesForm = ({
  estateType,
}: EstateConditionAndServicesFormProps) => {
  const { t } = useTranslation()

  const { control } = useFormContext<EstateUpdateSchema>()

  const showServiceInput = useCallback(
    (service: keyof EstateServicesAndConditions) => {
      return (
        estateType != null &&
        (servicesAndConditionsVisibilityMatrix[service] === true ||
          (Array.isArray(servicesAndConditionsVisibilityMatrix[service]) &&
            !servicesAndConditionsVisibilityMatrix[service].includes(
              estateType
            )))
      )
    },
    [estateType]
  )

  const renderField = (
    name: keyof EstateServicesAndConditions,
    isService: boolean
  ) => {
    const Component = isService ? ServiceRating : ConditionRating

    return (
      showServiceInput(name) && (
        <FormField
          control={control}
          name={`servicesAndConditions.${name}`}
          render={({ field }) => (
            <FormItem className="tw-col-span-3">
              <FormLabel>
                {t(`EstateConditionAndServicesForm.${name}`)}
              </FormLabel>
              <FormControl>
                <Component {...field} />
              </FormControl>
            </FormItem>
          )}
        />
      )
    )
  }

  return (
    <div className="tw-grid tw-grid-cols-6 tw-gap-4 tw-items-baseline">
      {renderField('overallCondition', false)}

      {renderField('bathroomShowerServices', true)}
      {renderField('bathroomShowerCondition', false)}

      {renderField('kitchenServices', true)}
      {renderField('kitchenCondition', false)}

      {renderField('flooringServices', true)}
      {renderField('flooringCondition', false)}

      {renderField('windowServices', true)}
      {renderField('windowCondition', false)}

      {renderField('masonryServices', true)}
      {renderField('masonryCondition', false)}

      {renderField('roofingServices', true)}
      {renderField('roofingCondition', false)}
    </div>
  )
}
