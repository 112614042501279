import {
  SellerMemberFragment,
  SellerWithAgencyAndMembersFragment,
  SellerWithAgencyFragment,
} from '@gql'
import { omit } from '@utils/omit'
import { Action, action } from 'easy-peasy'

interface SellerModel {
  currentId: string | undefined
  current: SellerWithAgencyFragment | undefined
  members: SellerMemberFragment[] | undefined
  loading: boolean
  error: Error | undefined
  // Set Id instantly from URL params
  setCurrentId: Action<SellerModel, string | undefined>
  // Set current after loading
  setSubscriptionResult: Action<
    SellerModel,
    {
      result: SellerWithAgencyAndMembersFragment | undefined
      loading: boolean
      error: Error | undefined
    }
  >
}

const model: SellerModel = {
  currentId: undefined,
  current: undefined,
  members: undefined,
  loading: false,
  error: undefined,

  setCurrentId: action((state, id) => {
    if (state.currentId !== id) {
      state.currentId = id
      state.members = undefined
    }
  }),

  setSubscriptionResult: action((state, { result, loading, error }) => {
    if (result) {
      state.current = omit(result, 'seller_members')

      // Get and sort members
      state.members = [...result.seller_members].sort((a, b) => {
        if (a?.user?.metadata?.lastName && b?.user?.metadata?.lastName)
          return (
            a.user.metadata.lastName?.localeCompare(b.user.metadata.lastName) ||
            0
          )
        if (a?.user?.email && b?.user?.email)
          return a.user.email?.localeCompare(b.user.email) || 0
        return 0
      })
    }
    state.loading = loading
    state.error = error
  }),
}

export default model
