// import { useEmailSchema } from '@/common/hooks/schemas/useEmailSchema'
import { useNameSchema } from '@/common/hooks/schemas/useNameSchema'
import { usePhoneNumberSchema } from '@/common/hooks/schemas/usePhoneNumberSchema'
import {
  Civility_Enum,
  Seller_Legal_Status_Enum,
  Seller_Marital_Status_Enum,
  Seller_Tax_System_Enum,
  Seller_Type_Enum,
} from '@gql'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useSellerInfoSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })

  const { schema: nameSchema } = useNameSchema()
  const { schema: phoneSchema } = usePhoneNumberSchema()

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: nameSchema.required(t('required')),
        logo: yup.string().optional(),
        linkUrl: yup.string().url(t('url')).optional(),
        type: yup
          .mixed<Seller_Type_Enum>()
          .oneOf(Object.values(Seller_Type_Enum))
          .required(t('required')),
        legalStatus: yup
          .string()
          .nullable()
          .oneOf([...Object.values(Seller_Legal_Status_Enum), null]),
        taxSystem: yup
          .string()
          .nullable()
          .oneOf([...Object.values(Seller_Tax_System_Enum), null]),
        businessName: yup.string().optional(),
        siren: yup.string().optional(),
        headquarter: yup.string().optional(),
        kbis: yup.string().optional(),
        tva: yup.boolean().required(t('required')),
        tvaNumber: yup.string().optional(),
        civility: yup
          .mixed<Civility_Enum>()
          .oneOf(Object.values(Civility_Enum))
          .required(t('required')),
        firstName: yup.string().required(t('required')),
        lastName: yup.string().required(t('required')),
        birthName: yup.string().optional(),
        job: yup.string().optional(),
        maritalStatus: yup.mixed<Seller_Marital_Status_Enum>().when('type', {
          is: Seller_Type_Enum.LegalEntity,
          then: (schema) => schema.optional(),
          otherwise: (schema) =>
            schema.oneOf(Object.values(Seller_Marital_Status_Enum)),
        }),
        address: yup.string().when('type', {
          is: Seller_Type_Enum.LegalEntity,
          then: (schema) => schema.optional(),
          otherwise: (schema) => schema.required(t('required')),
        }),
        mobilePhone: phoneSchema.required(t('required')),
        officePhone: phoneSchema.optional(),
        homePhone: phoneSchema.optional(),
        birthDate: yup.date().when('type', {
          is: Seller_Type_Enum.NaturalPerson,
          then: (schema) => schema.required(t('required')),
          otherwise: (schema) => schema.optional(),
        }),
        birthPlace: yup.string().when('type', {
          is: Seller_Type_Enum.NaturalPerson,
          then: (schema) => schema.required(t('required')),
          otherwise: (schema) => schema.optional(),
        }),
        nationality: yup.string().when('type', {
          is: Seller_Type_Enum.NaturalPerson,
          then: (schema) => schema.required(t('required')),
          otherwise: (schema) => schema.optional(),
        }),
        taxCountry: yup.string().optional(),
        idCard: yup.string().when('type', {
          is: Seller_Type_Enum.NaturalPerson,
          then: (schema) => schema.required(t('required')),
          otherwise: (schema) => schema.optional(),
        }),
        comment: yup.string().optional(),
        iban: yup.string().optional(),
        bic: yup.string().optional(),
        accountHolder: yup.string().optional(),
        bankName: yup.string().optional(),
        bankAddress: yup.string().optional(),
        rib: yup.string().optional(),
      }),
    [t]
  )

  return { schema }
}

export type SellerInfoFormValues = yup.InferType<
  ReturnType<typeof useSellerInfoSchema>['schema']
>
