import { DEFAULT_DATE_FORMAT } from '@almaris/shared/utils/dates'

import { parseDate } from '@almaris/shared/utils/dates'
import { WebappSimpleEstateFragment } from '@gql'

export enum EstateStatus {
  /** Procedure en cours */
  OnGoing = 'onGoing',
  MandateSigned = 'mandateSigned',
  AOInProgress = 'aoInProgress',
  AODone = 'aoDone',
  Archived = 'archived',
  ApplicationAccepted = 'applicationAccepted',
}

/**
 * TODO move into hasura ?
 * - onGoing - En cours de complétion : au moment de la création du bien jusqu'à la signature du mandat
 * - mandateSigned - Mandat signé : le mandat est chargé et la date de démarrage de la vente n'est pas encore atteint
 * - aoInProgress - Appel d’offre en cours : Le mandat est chargé et la date de démarrage de la vente est lancé
 * - aoDone - Appel d'offre terminé : la date de fin de la vente est échue
 * - archived - archivé
 * @param estate
 * @returns
 */
export const getEstateStatus = (
  estate: WebappSimpleEstateFragment
): EstateStatus => {
  const { currentRoundId, mandate, archived } = estate
  const currentRound = estate.estateRounds.find(
    (round) => round.id === currentRoundId
  )

  if (!currentRound) return EstateStatus.OnGoing

  const now = new Date()
  const hasMandate = !!mandate?.mandate_media?.length
  const { startDate, endDate } = currentRound
  const parsedStartDate = parseDate(startDate, DEFAULT_DATE_FORMAT) ?? now
  const parsedEndDate = parseDate(endDate, DEFAULT_DATE_FORMAT) ?? now
  const isOnSale = estate.isSaleOpen
  const applicationAccepted = estate.applicationAcceptedDate != null

  if (archived) {
    return EstateStatus.Archived
  }

  if (applicationAccepted) {
    return EstateStatus.ApplicationAccepted
  }

  if (now.getTime() > parsedEndDate.getTime()) {
    return EstateStatus.AODone
  }

  // TODO: Should be
  // if (hasMandate && isOnSale && now.getTime() > parsedStartDate.getTime())
  // But we disabled the mandatory mandate for now
  if (isOnSale && now.getTime() > parsedStartDate.getTime()) {
    return EstateStatus.AOInProgress
  }

  return hasMandate ? EstateStatus.MandateSigned : EstateStatus.OnGoing
}

export const getEstateStatusBasedLink = (
  estate: WebappSimpleEstateFragment
) => {
  const status = getEstateStatus(estate)
  let link = `estates/${estate.id}`

  switch (status) {
    case EstateStatus.OnGoing:
      link = `estates/${estate.id}`
      break
    case EstateStatus.MandateSigned:
      link = `estates/${estate.id}/mandate`
      break
    case EstateStatus.AOInProgress:
    case EstateStatus.AODone:
    case EstateStatus.ApplicationAccepted:
    case EstateStatus.Archived:
      link = `estates/${estate.id}/sale`
      break
  }
  return link
}
