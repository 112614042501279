// Component copied from https://shadcn-country-dropdown.vercel.app/
// Modified to use alpha2 instead of alpha3
// Plus handling translation (using our translations files)

'use client'
import React, { useCallback, useState, forwardRef, useEffect } from 'react'

// shadcn
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

// utils
import { cn } from '@/lib/utils'
import { useTranslation } from 'react-i18next'

// assets
import { ChevronDown, CheckIcon, Globe } from 'lucide-react'
import { CircleFlag } from 'react-circle-flags'

// data
import { countries } from 'country-data-list'

// Country interface
export interface Country {
  alpha2: string
  alpha3: string
  countryCallingCodes: string[]
  currencies: string[]
  emoji?: string
  ioc: string
  languages: string[]
  name: string
  status: string
}

// Dropdown props
interface CountryDropdownProps {
  options?: Country[]
  onChange?: (country: Country) => void
  defaultValue?: string
  disabled?: boolean
  placeholder?: string
  slim?: boolean
}

const CountryDropdownComponent = (
  {
    // Setting FR as the first option and enabling only "assigned" countries
    options = [
      countries.all.find((country: Country) => country.alpha2 === 'FR'),
      ...countries.all.filter(
        (country: Country) =>
          country.emoji &&
          country.status === 'assigned' &&
          country.ioc !== 'PRK' &&
          country.alpha2 !== 'FR'
      ),
    ].filter(Boolean) as Country[],
    onChange,
    defaultValue,
    disabled = false,
    placeholder = 'Select a country',
    slim = false,
    ...props
  }: CountryDropdownProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>(
    undefined
  )

  useEffect(() => {
    if (defaultValue) {
      const initialCountry = options.find(
        (country) => country.alpha2 === defaultValue
      )
      if (initialCountry) {
        setSelectedCountry(initialCountry)
      } else {
        // Reset selected country if defaultValue is not found
        setSelectedCountry(undefined)
      }
    } else {
      // Reset selected country if defaultValue is undefined or null
      setSelectedCountry(undefined)
    }
  }, [defaultValue, options])

  const handleSelect = useCallback(
    (country: Country) => {
      setSelectedCountry(country)
      onChange?.(country)
      setOpen(false)
    },
    [onChange]
  )

  const triggerClasses = cn(
    'tw-flex tw-h-9 tw-w-full tw-items-center tw-justify-between tw-whitespace-nowrap tw-rounded-md tw-border tw-border-input tw-bg-transparent tw-px-3 tw-py-2 tw-text-sm tw-shadow-sm tw-ring-offset-background tw-placeholder:tw-text-muted-foreground focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-ring disabled:tw-cursor-not-allowed disabled:tw-opacity-50 [&>span]:tw-line-clamp-1',
    slim === true && 'tw-w-20'
  )

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        ref={ref}
        className={triggerClasses}
        disabled={disabled}
        {...props}
      >
        {selectedCountry ? (
          <div className="tw-flex tw-items-center tw-flex-grow tw-w-0 tw-gap-2 tw-overflow-hidden">
            <div className="tw-inline-flex tw-items-center tw-justify-center tw-w-5 tw-h-5 tw-shrink-0 tw-overflow-hidden tw-rounded-full">
              <CircleFlag
                countryCode={selectedCountry.alpha2.toLowerCase()}
                height={20}
              />
            </div>
            {slim === false && (
              <span className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
                {selectedCountry.name}
              </span>
            )}
          </div>
        ) : (
          <span>
            {slim === false ? (
              placeholder || setSelectedCountry.name
            ) : (
              <Globe size={20} />
            )}
          </span>
        )}
        <ChevronDown size={16} />
      </PopoverTrigger>
      <PopoverContent
        collisionPadding={10}
        side="bottom"
        className="tw-min-w-[--radix-popper-anchor-width] tw-p-0"
      >
        <Command className="tw-w-full tw-max-h-[200px] tw-sm:tw-max-h-[270px]">
          <CommandList>
            <div className="tw-sticky tw-top-0 tw-z-10 tw-bg-popover">
              <CommandInput placeholder="Search country..." />
            </div>
            <CommandEmpty>No country found.</CommandEmpty>
            <CommandGroup>
              {options
                .filter((x) => x.name)
                .map((option, key: number) => (
                  <CommandItem
                    className="tw-flex tw-items-center tw-w-full tw-gap-2"
                    key={key}
                    onSelect={() => handleSelect(option)}
                  >
                    <div className="tw-flex tw-flex-grow tw-w-0 tw-space-x-2 tw-overflow-hidden">
                      <div className="tw-inline-flex tw-items-center tw-justify-center tw-w-5 tw-h-5 tw-shrink-0 tw-overflow-hidden tw-rounded-full">
                        <CircleFlag
                          countryCode={option.alpha2.toLowerCase()}
                          height={20}
                        />
                      </div>
                      <span className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
                        {
                          // @ts-ignore - ignore translation typing for countries
                          t(`countries.${option.alpha2}`).toString()
                        }
                      </span>
                    </div>
                    <CheckIcon
                      className={cn(
                        'tw-ml-auto tw-h-4 tw-w-4 tw-shrink-0',
                        option.name === selectedCountry?.name
                          ? 'tw-opacity-100'
                          : 'tw-opacity-0'
                      )}
                    />
                  </CommandItem>
                ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

CountryDropdownComponent.displayName = 'CountryDropdownComponent'

export const CountryDropdown = forwardRef(CountryDropdownComponent)
