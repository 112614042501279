import React, { forwardRef, useMemo } from 'react'
import { StarsRating, StarsRatingProps } from '@/common/atoms/StarsRating'
import { useTranslation } from 'react-i18next'

export type ConditionRatingProps = StarsRatingProps & {
  hideLabel?: boolean
}
const ConditionRating = forwardRef<HTMLDivElement, ConditionRatingProps>(
  ({ max, value, onChange, hideLabel, ...props }, ref) => {
    const { t } = useTranslation()

    const label = useMemo(() => {
      if (hideLabel) return undefined
      if (value === 0) return t('ConditionRating.unknown')
      if (value === 1) return t('ConditionRating.poor')
      if (value === 2) return t('ConditionRating.good')
      if (value === 3) return t('ConditionRating.new')
    }, [value, t, hideLabel])

    return (
      <StarsRating
        ref={ref}
        {...props}
        max={3}
        onChange={onChange}
        value={value}
        label={label}
      />
    )
  }
)

ConditionRating.displayName = 'ConditionRating'
export { ConditionRating }
