import {
  AddressCombobox,
  AddressComboboxModeEnum,
} from '@/api-adresse/AddressCombobox'
import { CivilitySelect } from '@/common/atoms/CivilitySelect'
import { FileUploadInput } from '@/common/atoms/FileUploadInput'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { InputDatePicker } from '@/components/ui/InputDatePicker'
import { Label } from '@/components/ui/label'
import { Separator } from '@/components/ui/separator'
import { Switch } from '@/components/ui/switch'
import { Textarea } from '@/components/ui/textarea'
import { useToast } from '@/components/ui/use-toast'
import { Civility_Enum, Seller_Type_Enum, useUpdateSellerMutation } from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect, useMemo } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useCurrentSeller from '../hooks/useCurrentSeller'
import useCurrentSellerMembers from '../hooks/useCurrentSellerMembers'
import {
  SellerInfoFormValues,
  useSellerInfoSchema,
} from '../hooks/useSellerInfoSchema'
import { SellerLegalStatusSelect } from './SellerLegalStatusSelect'
import { SellerMaritalStatusSelect } from './SellerMaritalStatusSelect'
import { SellerTaxSystemSelect } from './SellerTaxSystemSelect'
import { SellerTypeSelect } from './SellerTypeSelect'
import { PhoneInput } from '@/components/ui/phone-input'
import { CountryDropdown } from '@/components/ui/country-dropdown'
// @ts-ignore
import fr from 'react-phone-number-input/locale/fr'

export type SellerInfoFormProps = {}
export const SellerInfoForm = () => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const { schema } = useSellerInfoSchema()
  const seller = useCurrentSeller()
  const members = useCurrentSellerMembers()

  const [updateSeller] = useUpdateSellerMutation({
    refetchQueries: ['getSeller'], // TODO get new data from response instead of refreshing
  })

  // handle file storage name for uploads
  const storageName = `sellers/${seller?.id}/uploads/`

  const mainContact = useMemo(
    () => members?.find((m) => m.isMainContact),
    [members]
  )

  const defaultValues = useMemo<SellerInfoFormValues>(
    () => ({
      name: seller?.name ?? '',
      logo: seller?.logo ?? undefined,
      // email: seller?.email ?? '',
      linkUrl: seller?.linkUrl ?? '',
      type: seller?.type ?? Seller_Type_Enum.Inheritance,
      accountHolder: seller?.accountHolder ?? '',
      civility: seller?.civility ?? Civility_Enum.Mr,
      lastName: seller?.lastName ?? mainContact?.user?.metadata?.lastName ?? '',
      firstName:
        seller?.firstName ?? mainContact?.user?.metadata?.firstName ?? '',
      birthName: seller?.birthName ?? undefined,
      job: seller?.job ?? undefined,
      maritalStatus: seller?.maritalStatus ?? undefined,
      address: seller?.address ?? '',
      mobilePhone: seller?.mobilePhone ?? mainContact?.user?.phoneNumber ?? '',
      officePhone: seller?.officePhone ?? undefined,
      homePhone: seller?.homePhone ?? undefined,
      birthDate: seller?.birthDate ? new Date(seller.birthDate) : undefined,
      birthPlace: seller?.birthPlace ?? '',
      nationality: seller?.nationality ?? '',
      taxCountry: seller?.taxCountry ?? undefined,
      idCard: seller?.idCard ?? undefined,
      comment: seller?.comment ?? undefined,
      iban: seller?.iban ?? '',
      bic: seller?.bic ?? '',
      tva: seller?.tva ?? false,
      tvaNumber: seller?.tvaNumber ?? undefined,
      bankName: seller?.bankName ?? undefined,
      bankAddress: seller?.bankAddress ?? undefined,
      rib: seller?.rib ?? undefined,
      businessName: seller?.businessName ?? undefined,
      siren: seller?.siren ?? undefined,
      headquarter: seller?.headquarter ?? undefined,
      kbis: seller?.kbis ?? undefined,
      legalStatus: seller?.legalStatus ?? undefined,
      taxSystem: seller?.taxSystem ?? undefined,
    }),
    [seller, mainContact]
  )

  const form = useForm<SellerInfoFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onSubmit = async (input: SellerInfoFormValues) => {
    if (!seller) return

    await updateSeller({
      variables: {
        id: String(seller.id),
        values: {
          name: input.name,
          logo: input.logo,
          // email: input.email,
          linkUrl: input.linkUrl,
          type: input.type,
          legalStatus: input.legalStatus,
          taxSystem: input.taxSystem,
          businessName: input.businessName,
          siren: input.siren,
          headquarter: input.headquarter,
          kbis: input.kbis,
          tva: input.tva,
          tvaNumber: input.tvaNumber,
          civility: input.civility,
          firstName: input.firstName,
          lastName: input.lastName,
          birthName: input.birthName,
          job: input.job,
          maritalStatus: input.maritalStatus,
          address: input.address,
          mobilePhone: input.mobilePhone,
          officePhone: input.officePhone,
          homePhone: input.homePhone,
          birthDate: input.birthDate?.toISOString(),
          birthPlace: input.birthPlace,
          nationality: input.nationality,
          taxCountry: input.taxCountry,
          idCard: input.idCard,
          comment: input.comment,
          iban: input.iban,
          bic: input.bic,
          accountHolder: input.accountHolder,
          bankName: input.bankName,
          bankAddress: input.bankAddress,
          rib: input.rib,
        },
      },
      onCompleted: () => {
        toast({
          variant: 'success',
          description: t('SellerInfoForm.toast.success'),
        })
      },
      onError: (error) => {
        console.log(error)
        toast({
          variant: 'destructive',
          description: t('SellerInfoForm.toast.error'),
        })
      },
    })
  }

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues)
    }
  }, [defaultValues, form])

  const [type, tva] = form.watch(['type', 'tva']) // TODO use useWatch

  const isLegalEntity = type === Seller_Type_Enum.LegalEntity

  const onInvalid = useCallback(
    (errors: FieldErrors<SellerInfoFormValues>) => {
      toast({
        variant: 'destructive',
        description: t('SellerInfoForm.toast.error'),
      })
      console.log({ errors })
    },
    [t, toast]
  )

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit, onInvalid)}>
        <Card>
          <CardContent className="tw-space-y-6 tw-mt-6">
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => <SellerTypeSelect field={field} />}
            />

            {isLegalEntity && (
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('SellerInfoForm.name')} *</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            {isLegalEntity && (
              <FormField
                control={form.control}
                name="logo"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('SellerInfoForm.logo')}</FormLabel>
                    <FormControl>
                      <FileUploadInput storageName={storageName} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            {isLegalEntity && (
              <FormField
                control={form.control}
                name="linkUrl"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('SellerInfoForm.linkUrl')}</FormLabel>
                    <FormControl>
                      <Input type="url" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
          </CardContent>
        </Card>

        <Separator className="tw-my-6" />

        <h2 className="tw-text-2xl tw-font-semibold tw-mb-4">
          {t('SellerInfoForm.heading.coordinates')}
        </h2>
        <Card>
          <CardContent className="tw-space-y-6 tw-mt-6">
            {isLegalEntity && (
              <>
                <div className="tw-flex tw-space-x-4">
                  <FormField
                    control={form.control}
                    name="legalStatus"
                    render={({ field }) => (
                      <SellerLegalStatusSelect
                        field={field}
                        className="tw-w-1/2"
                      />
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="taxSystem"
                    render={({ field }) => (
                      <SellerTaxSystemSelect
                        field={field}
                        className="tw-w-1/2"
                      />
                    )}
                  />
                </div>
                <div className="tw-flex tw-space-x-4">
                  <FormField
                    control={form.control}
                    name="businessName"
                    render={({ field }) => (
                      <FormItem className="tw-w-1/2">
                        <FormLabel>
                          {t('SellerInfoForm.businessName')}
                        </FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="siren"
                    render={({ field }) => (
                      <FormItem className="tw-w-1/2">
                        <FormLabel>{t('SellerInfoForm.siren')}</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div>
                  <div className="tw-flex">
                    <FormField
                      control={form.control}
                      name="tva"
                      render={({ field }) => (
                        <FormItem className="tw-grid tw-w-1/2">
                          <FormLabel className="tw-flex tw-items-center">
                            {t('SellerInfoForm.tva')}
                          </FormLabel>
                          <div className="tw-flex tw-items-center tw-space-x-2">
                            <FormControl>
                              <Switch
                                id="tva"
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                            <Label htmlFor="tva">
                              {t('SellerInfoForm.tvaAnswer')}
                            </Label>
                          </div>
                        </FormItem>
                      )}
                    />
                    {!!tva && (
                      <FormField
                        control={form.control}
                        name="tvaNumber"
                        render={({ field }) => (
                          <FormItem className="tw-w-1/2">
                            <FormLabel>
                              {t('SellerInfoForm.tvaNumber')}
                            </FormLabel>
                            <FormControl>
                              <Input {...field} disabled={!form.watch('tva')} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                  </div>
                </div>
                <FormField
                  control={form.control}
                  name="headquarter"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>
                          {t('SellerInfoForm.headquarter')} *
                        </FormLabel>
                        <FormControl>
                          <AddressCombobox
                            {...field}
                            onSelectItem={({ formatted }) => {
                              form.setValue('headquarter', formatted)
                            }}
                            mode={AddressComboboxModeEnum.ADDRESS}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />
                <FormField
                  control={form.control}
                  name="kbis"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('SellerInfoForm.kbis')}</FormLabel>
                      <FormControl>
                        <FileUploadInput storageName={storageName} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}
            <FormField
              control={form.control}
              name="civility"
              render={({ field }) => (
                <CivilitySelect field={field} className="tw-w-1/2" />
              )}
            />
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('SellerInfoForm.firstName')} *</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('SellerInfoForm.lastName')} *</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="tw-flex tw-space-x-4">
              <FormField
                control={form.control}
                name="birthName"
                render={({ field }) => (
                  <FormItem className="tw-w-1/2">
                    <FormLabel>{t('SellerInfoForm.birthName')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {isLegalEntity && (
                <FormField
                  control={form.control}
                  name="job"
                  render={({ field }) => (
                    <FormItem className="tw-w-1/2">
                      <FormLabel>{t('SellerInfoForm.job')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>
            {!isLegalEntity && (
              <FormField
                control={form.control}
                name="maritalStatus"
                render={({ field }) => (
                  <SellerMaritalStatusSelect
                    field={field}
                    className="tw-w-1/2"
                  />
                )}
              />
            )}
            {!isLegalEntity && (
              <FormField
                control={form.control}
                name="address"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>{t('SellerInfoForm.address')} *</FormLabel>
                      <FormControl>
                        <AddressCombobox
                          {...field}
                          onSelectItem={({ formatted }) => {
                            form.setValue('address', formatted)
                          }}
                          mode={AddressComboboxModeEnum.ADDRESS}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            )}
            <div className="tw-flex tw-space-x-4">
              <FormField
                control={form.control}
                name="mobilePhone"
                render={({ field }) => (
                  <FormItem className="tw-w-1/2">
                    <FormLabel>{t('SellerInfoForm.mobilePhone')} *</FormLabel>
                    <FormControl>
                      <PhoneInput
                        international
                        focusInputOnCountrySelection
                        labels={fr}
                        defaultCountry="FR"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {isLegalEntity && (
                <FormField
                  control={form.control}
                  name="officePhone"
                  render={({ field }) => (
                    <FormItem className="tw-w-1/2">
                      <FormLabel>{t('SellerInfoForm.officePhone')}</FormLabel>
                      <FormControl>
                        <PhoneInput
                          defaultCountry="FR"
                          international
                          focusInputOnCountrySelection
                          labels={fr}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              {!isLegalEntity && (
                <FormField
                  control={form.control}
                  name="homePhone"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('SellerInfoForm.homePhone')}</FormLabel>
                      <FormControl>
                        <PhoneInput
                          international
                          focusInputOnCountrySelection
                          labels={fr}
                          defaultCountry="FR"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>
            <div className="tw-flex tw-space-x-4">
              {!isLegalEntity && (
                <FormField
                  control={form.control}
                  name="birthDate"
                  render={({ field }) => (
                    <FormItem className="tw-w-1/2">
                      <FormLabel>{t('SellerInfoForm.birthDate')} *</FormLabel>
                      <FormControl>
                        <InputDatePicker
                          {...field}
                          fromYear={1900}
                          toYear={new Date().getFullYear()}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              {!isLegalEntity && (
                <FormField
                  control={form.control}
                  name="birthPlace"
                  render={({ field }) => (
                    <FormItem className="tw-w-1/2">
                      <FormLabel>{t('SellerInfoForm.birthPlace')} *</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>
            {!isLegalEntity && (
              <FormField
                control={form.control}
                name="nationality"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('SellerInfoForm.nationality')}</FormLabel>
                    <CountryDropdown
                      placeholder={t('SellerInfoForm.nationality')}
                      defaultValue={field.value}
                      onChange={(country) => {
                        field.onChange(country.alpha2)
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            {!isLegalEntity && (
              <FormField
                control={form.control}
                name="taxCountry"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('SellerInfoForm.taxCountry')}</FormLabel>
                    <CountryDropdown
                      placeholder={t('SellerInfoForm.taxCountry')}
                      defaultValue={field.value}
                      onChange={(country) => {
                        field.onChange(country.alpha2)
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <FormField
              control={form.control}
              name="idCard"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('SellerInfoForm.idCard')}
                    {!isLegalEntity && ' *'}
                  </FormLabel>
                  <FormControl>
                    <FileUploadInput storageName={storageName} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="comment"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('SellerInfoForm.comment')}</FormLabel>
                  <FormControl>
                    <Textarea {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
        </Card>

        {/* <Separator className="tw-my-6" />

        <h2 className="tw-text-2xl tw-font-semibold tw-mb-4">
          {t('SellerInfoForm.heading.bankInformations')}
        </h2>
        <Card>
          <CardHeader />
          <CardContent className="tw-space-y-6">
            <FormField
              control={form.control}
              name="iban"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('SellerInfoForm.iban')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="bic"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('SellerInfoForm.bic')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="accountHolder"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('SellerInfoForm.accountHolder')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="bankName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('SellerInfoForm.bankName')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="bankAddress"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('SellerInfoForm.bankAddress')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="rib"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('SellerInfoForm.rib')}</FormLabel>
                  <FormControl>
                    <FileUploadInput storageName={storageName} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
        </Card> */}
        <Button type="submit" className="tw-mt-6 tw-w-full">
          {t('SellerInfoForm.submit')}
        </Button>
      </form>
    </Form>
  )
}
