import {
  AddressCombobox,
  AddressComboboxModeEnum,
} from '@/api-adresse/AddressCombobox'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EstateTypeSelect } from './EstateTypeSelect'
import { ConditionRating } from './ConditionRating'
import { InputNumber } from '@/components/ui/inputNumber'
import { EstateUpdateSchema } from '@/estate/hooks/useUpdateEstateSchema'

export type EstateIdentityAndAddressFormProps = {
  isMandateGenerated: boolean
}

export const EstateIdentityAndAddressForm = ({
  isMandateGenerated,
}: EstateIdentityAndAddressFormProps) => {
  const { t } = useTranslation()

  const { control, setValue } = useFormContext<EstateUpdateSchema>()

  return (
    <div className="tw-grid tw-grid-cols-6 tw-gap-4 tw-items-end">
      <FormField
        control={control}
        name="name"
        render={({ field }) => (
          <FormItem className="tw-col-span-6">
            <FormLabel>{t('EstateIdentityAndAddressForm.name')}</FormLabel>
            <FormControl>
              <Input
                {...field}
                value={field.value === null ? '' : field.value}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="tw-col-span-6">
        <FormField
          control={control}
          name="type"
          render={({ field }) => <EstateTypeSelect {...field} />}
        />
      </div>

      <FormField
        control={control}
        name="address"
        render={({ field }) => {
          return (
            <FormItem className="tw-col-span-6">
              <FormLabel>
                {t('EstateIdentityAndAddressForm.address')} *
              </FormLabel>
              <FormControl>
                <AddressCombobox
                  disabled={isMandateGenerated}
                  {...field}
                  onSelectItem={({
                    addressLabel,
                    postalCode,
                    city,
                    country,
                  }) => {
                    setValue('address', addressLabel)
                    setValue('zipCode', postalCode ?? '')
                    setValue('city', city)
                    // TODO add country
                    // form.setValue('country', country)
                  }}
                  mode={AddressComboboxModeEnum.ADDRESS}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )
        }}
      />

      <FormField
        control={control}
        name="city"
        render={({ field }) => {
          return (
            <FormItem className="tw-col-span-4">
              <FormLabel>{t('EstateIdentityAndAddressForm.city')} *</FormLabel>
              <FormControl>
                <AddressCombobox
                  disabled={isMandateGenerated}
                  {...field}
                  onSelectItem={({ city, country }) => {
                    setValue('city', city)
                    // TODO add country
                    // form.setValue('country', country)
                  }}
                  mode={AddressComboboxModeEnum.CITY}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )
        }}
      />

      <FormField
        control={control}
        name="zipCode"
        render={({ field }) => {
          return (
            <FormItem className="tw-col-span-2">
              <FormLabel>
                {t('EstateIdentityAndAddressForm.zipCode')} *
              </FormLabel>
              <FormControl>
                <AddressCombobox
                  disabled={isMandateGenerated}
                  {...field}
                  onSelectItem={({ postalCode, city, country }) => {
                    setValue('zipCode', postalCode ?? '')
                    setValue('city', city)
                    // TODO add country
                    // form.setValue('country', country)
                  }}
                  mode={AddressComboboxModeEnum.ZIP_CODE}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )
        }}
      />

      <FormField
        control={control}
        name="features.habitableSurface"
        render={({ field }) => (
          <FormItem className="tw-col-span-2">
            <FormLabel>{t('EstateFeaturesForm.habitableSurface')}</FormLabel>
            <FormControl>
              <InputNumber {...field} className="tw-w-full" min={0} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="features.numberOfRooms"
        render={({ field }) => (
          <FormItem className="tw-col-span-2">
            <FormLabel>{t('EstateFeaturesForm.numberOfRooms')}</FormLabel>
            <FormControl>
              <InputNumber {...field} className="tw-w-full" min={0} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="servicesAndConditions.overallCondition"
        render={({ field }) => (
          <FormItem className="tw-col-span-2">
            <FormLabel>
              {t('EstateConditionAndServicesForm.overallCondition')}
            </FormLabel>
            <FormControl>
              <ConditionRating {...field} />
            </FormControl>
          </FormItem>
        )}
      />
    </div>
  )
}
