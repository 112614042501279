import { Estate_Type_Enum } from '../gql'

export interface EstateFeatures {
  building?: string
  propertyFloor?: number
  door?: string
  numberOfFloors?: number
  constructionYear?: number
  carrezLawSurface?: number
  habitableSurface?: number
  propertyDescription?: string
  primaryPurpose?: string
  landSurface?: number
  gardenSurface?: number
  balconyTerraceSurface?: number
  pool?: boolean
  furnished?: boolean
  numberOfRooms?: number
  numberOfBedrooms?: number
  numberOfBathrooms?: number
  numberOfToilets?: number
  numberOfGarages?: number
  numberOfParkings?: number
  cellar?: boolean
  orientation?: string[]
  equipments?: string[]
  propertyFreeOfOccupation?: boolean
  occupationType?: string
  accessDetails?: string
  propertyTaxAmount?: number
  housingTaxAmount?: number
  annualChargesAmount?: number
}

/**
 * true: visible for all types
 * []: visible for all types except specified types
 * false: not visible
 */
export const featuresVisibilityMatrix: Record<
  keyof EstateFeatures,
  Array<Estate_Type_Enum> | boolean
> = {
  building: [Estate_Type_Enum.Land],
  propertyFloor: [
    Estate_Type_Enum.Block,
    Estate_Type_Enum.Building,
    Estate_Type_Enum.Castle,
    Estate_Type_Enum.House,
    Estate_Type_Enum.Land,
    Estate_Type_Enum.Mansion,
  ],
  door: [
    Estate_Type_Enum.Block,
    Estate_Type_Enum.Building,
    Estate_Type_Enum.Castle,
    Estate_Type_Enum.House,
    Estate_Type_Enum.Land,
    Estate_Type_Enum.Mansion,
  ],
  numberOfFloors: [Estate_Type_Enum.Land],
  constructionYear: [Estate_Type_Enum.Land],
  carrezLawSurface: [Estate_Type_Enum.Land, Estate_Type_Enum.Parking],
  habitableSurface: [Estate_Type_Enum.Land],
  propertyDescription: true,
  primaryPurpose: true,
  landSurface: [
    Estate_Type_Enum.Apartment,
    Estate_Type_Enum.Loft,
    Estate_Type_Enum.Parking,
  ],
  balconyTerraceSurface: [Estate_Type_Enum.Land, Estate_Type_Enum.Parking],
  gardenSurface: [Estate_Type_Enum.Land, Estate_Type_Enum.Parking],
  furnished: [Estate_Type_Enum.Land, Estate_Type_Enum.Parking],
  cellar: [Estate_Type_Enum.Land],
  pool: [Estate_Type_Enum.Land, Estate_Type_Enum.Parking],
  numberOfRooms: [Estate_Type_Enum.Land, Estate_Type_Enum.Parking],
  numberOfBedrooms: [
    Estate_Type_Enum.Land,
    Estate_Type_Enum.Parking,
    Estate_Type_Enum.Commercial,
    Estate_Type_Enum.Office,
    Estate_Type_Enum.Shop,
  ],
  numberOfBathrooms: [Estate_Type_Enum.Land, Estate_Type_Enum.Parking],
  numberOfToilets: [Estate_Type_Enum.Land, Estate_Type_Enum.Parking],
  numberOfGarages: [Estate_Type_Enum.Land],
  numberOfParkings: [Estate_Type_Enum.Land],
  orientation: [Estate_Type_Enum.Land, Estate_Type_Enum.Parking],
  equipments: [Estate_Type_Enum.Land],
  propertyFreeOfOccupation: true,
  occupationType: true,
  accessDetails: true,
  propertyTaxAmount: true,
  housingTaxAmount: true,
  annualChargesAmount: true,
}

export interface EstateSellerData {
  singleRepresentativeForMultipleSellers?: boolean
  mandatedSellerWithPowerDelegation?: string
}

export interface EstateCoownership {
  isCoowned?: boolean
  syndicExtranetURL?: string
  syndicExtranetLogin?: string
  syndicExtranetPassword?: string
  totalLotsNumber?: number
  ongoingProcedures?: boolean
}

export interface EstateServicesAndConditions {
  overallCondition?: number
  bathroomShowerServices?: number
  bathroomShowerCondition?: number
  kitchenServices?: number
  kitchenCondition?: number
  flooringServices?: number
  flooringCondition?: number
  windowServices?: number
  windowCondition?: number
  masonryServices?: number
  masonryCondition?: number
  roofingServices?: number
  roofingCondition?: number
}

/**
 * true: visible for all types
 * []: visible for all types except specified types
 * false: not visible
 */
export const servicesAndConditionsVisibilityMatrix: Record<
  keyof EstateServicesAndConditions,
  Array<Estate_Type_Enum> | boolean
> = {
  overallCondition: true,
  bathroomShowerServices: [Estate_Type_Enum.Land, Estate_Type_Enum.Parking],
  bathroomShowerCondition: [Estate_Type_Enum.Land, Estate_Type_Enum.Parking],
  kitchenServices: [Estate_Type_Enum.Land, Estate_Type_Enum.Parking],
  kitchenCondition: [Estate_Type_Enum.Land, Estate_Type_Enum.Parking],
  flooringServices: [Estate_Type_Enum.Land],
  flooringCondition: [Estate_Type_Enum.Land],
  windowServices: [Estate_Type_Enum.Land],
  windowCondition: [Estate_Type_Enum.Land],
  masonryServices: [
    Estate_Type_Enum.Apartment,
    Estate_Type_Enum.Land,
    Estate_Type_Enum.Parking,
    Estate_Type_Enum.Loft,
  ],
  masonryCondition: [
    Estate_Type_Enum.Apartment,
    Estate_Type_Enum.Land,
    Estate_Type_Enum.Parking,
    Estate_Type_Enum.Loft,
  ],
  roofingServices: [
    Estate_Type_Enum.Apartment,
    Estate_Type_Enum.Land,
    Estate_Type_Enum.Parking,
    Estate_Type_Enum.Loft,
  ],
  roofingCondition: [
    Estate_Type_Enum.Apartment,
    Estate_Type_Enum.Land,
    Estate_Type_Enum.Parking,
    Estate_Type_Enum.Loft,
  ],
}

export enum EPC_Rank_Enum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export interface EstateDiagnosticsData {
  asbestos?: boolean
  asbestosDate?: Date
  asbestosValidityDate?: Date
  carrez?: boolean
  carrezArea?: number
  carrezFloorArea?: number
  carrezDate?: Date
  epc?: boolean
  epcConsumption?: number
  epcEmission?: number
  epcDate?: Date
  epcConsumptionRank?: EPC_Rank_Enum
  epcEmissionRank?: EPC_Rank_Enum
  elec?: boolean
  elecDate?: Date
  elecValidityDate?: Date
  fungalDecay?: boolean
  fungalDecayDate?: Date
  gas?: boolean
  gasDate?: Date
  gasValidityDate?: Date
  lead?: boolean
  leadDate?: Date
  leadValidityDate?: Date
  noise?: boolean
  noiseDate?: Date
  noiseValidityDate?: Date
  ntr?: boolean
  ntrDate?: Date
  ntrValidityDate?: Date
  sanitation?: boolean
  sanitationDate?: Date
  sanitationValidityDate?: Date
  termites?: boolean
  termitesDate?: Date
  termitesValidityDate?: Date
} // TODO better typing

export interface EstateVirtualVisit {
  url: string
}

export interface EstateVirtualVisits {
  list?: EstateVirtualVisit[]
}
