import React, { useState } from 'react'
import { useStoreState } from '@store/hooks'
import { useTranslation } from 'react-i18next'
import { useToast } from '@/components/ui/use-toast'
import MemberItem from './MemberItem'
import { MemberAddForm } from './MemberAddForm'
import { trpc } from 'src/trpc'
import { Separator } from '@/components/ui/separator'
import { useUserData } from '@nhost/react'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import { MemberInfoFormValues } from '../hooks/useSellerMemberInfoSchema'

export const MembersList = () => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const isAgencyMember = useIsAgencyMember()
  const currentMember = useUserData()

  const sellerId = useStoreState((state) => state.seller.currentId)
  const members = useStoreState((state) => state.seller.members)

  const [loading, setLoading] = useState(false)

  const handleInvite = async (values: MemberInfoFormValues) => {
    if (!values.firstName || !values.lastName || !values.email) return
    setLoading(true)
    try {
      await trpc.member.inviteSellerMember.mutate({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        sellerId: String(sellerId),
        isMainContact: false,
      })

      toast({
        title: t('MembersList.inviteSuccess', {
          email: values.email,
        }) as string,
        description: t('MembersList.inviteSuccessDesc') as string,
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      toast({
        title: t('MembersList.inviteFailed', { email: values.email }) as string,
        description: t('MembersList.inviteFailedDesc') as string,
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }
  return (
    <div className="tw-space-y-3">
      <h1 className="tw-text-[40px] tw-font-semibold">
        {t('MembersList.heading')}
      </h1>
      <p>{t('MembersList.description')}</p>

      <Card>
        <CardHeader />
        <CardContent>
          {isAgencyMember && (
            <>
              <MemberAddForm loading={loading} onClick={handleInvite} />
              <Separator className="tw-my-6" />
            </>
          )}
          <div>
            {!members?.length && (
              <p className="tw-text-center tw-text-gray-500">
                {t('MembersList.noMembers')}
              </p>
            )}
            {members?.map((member, index) => (
              <MemberItem key={index} {...member} />
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
