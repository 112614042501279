import React, { useMemo } from 'react'
import { GetEstateVisitSpansQuery } from '@gql'
import { addMinutes, format } from 'date-fns'
import generateTimeSeriesRecursive from '@almaris/shared/helpers/generateTimeSeriesRecursive'
import { ScrollArea } from '@/components/ui/scroll-area'
import { EstateVisitDoneButton } from './EstateVisitDoneButton'
import { EstateVisitDeleteButton } from './EstateVisitDeleteButton'
import { useHasuraClaim } from '@nhost/react'

type EstateVisitSpanRowsProps = {
  visitSpan: GetEstateVisitSpansQuery['visit_span'][number]
}
export const EstateVisitSpanRows = ({
  visitSpan,
}: EstateVisitSpanRowsProps) => {
  const allTimeSeriesWithMember = useMemo(() => {
    if (!visitSpan || !visitSpan.span.length) return []
    const span = visitSpan.span
    const allTimeSeries = span
      .map((date) => {
        const startTime = format(new Date(date.startTime), 'HH:mm')
        const endTime = format(new Date(date.endTime), 'HH:mm')
        return generateTimeSeriesRecursive(
          new Date(date.startTime),
          startTime,
          endTime,
          30
        )
      })
      .flat()

    return allTimeSeries.map((date, index, timeSeries) => {
      const buyerSlot =
        visitSpan.visit_slots.filter(
          (slot) =>
            format(new Date(slot.startTime), 'HH:mm') === format(date, 'HH:mm')
        ) ?? []

      return {
        start: date,
        end: addMinutes(new Date(date), 30),
        buyerSlot,
      }
    })
  }, [])

  const handleDeleteVisitSlot = (
    visitSlotId: GetEstateVisitSpansQuery['visit_span'][number]['visit_slots'][number]['id']
  ) => {
    allTimeSeriesWithMember.forEach((date) => {
      date.buyerSlot = date.buyerSlot.filter((slot) => slot.id !== visitSlotId)
    })
  }

  const handleUpdateVisitSlot = (
    visitSlotId: GetEstateVisitSpansQuery['visit_span'][number]['visit_slots'][number]['id'],
    isVisitDone: boolean
  ) => {
    allTimeSeriesWithMember.forEach((date) => {
      date.buyerSlot = date.buyerSlot.map((slot) =>
        slot.id !== visitSlotId ? slot : { ...slot, isVisitDone }
      )
    })
  }

  const sellerMemberId = useHasuraClaim('seller-member-id')
  const readOnly = !!sellerMemberId && sellerMemberId !== 'null'

  return (
    <ScrollArea className="tw-h-[400px] tw-p-4 tw-pb-[2px]">
      <div className="tw-grid tw-grid-cols-1 tw-gap-4">
        {allTimeSeriesWithMember.map((date) => (
          <div
            key={`${date.start.toISOString()}`}
            className="tw-flex tw-gap-2 tw-text-zinc-500 tw-text-sm tw-items-center tw-justify-between"
          >
            <span>
              {format(date.start, 'HH:mm')} -{' '}
              {date.end && format(date.end, 'HH:mm')}
            </span>
            <div className="tw-flex tw-flex-col tw-gap-2">
              {date.buyerSlot.map((visitSlot) => (
                <div key={visitSlot.id} className="tw-flex tw-gap-2">
                  <div className="tw-text-zinc-900 tw-font-medium tw-flex tw-content-center tw-items-center">
                    <p className="tw-text-zinc-900 tw-font-medium">
                      {visitSlot.buyer_member.user?.displayName}
                    </p>
                  </div>
                  <EstateVisitDoneButton
                    readOnly={readOnly}
                    visitSlot={visitSlot}
                    onUpdate={(isVisitDone: boolean) =>
                      handleUpdateVisitSlot(visitSlot.id, isVisitDone)
                    }
                  />
                  {!visitSlot.isVisitDone && !readOnly && (
                    <EstateVisitDeleteButton
                      visitSlot={visitSlot}
                      onDelete={() => handleDeleteVisitSlot(visitSlot.id)}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </ScrollArea>
  )
}
