import { Badge, BadgeProps } from '@/components/ui/badge'
import { EstateStatus, getEstateStatus } from '@/estate/utils/getEstateStatus'
import { WebappSimpleEstateFragment } from '@gql'
import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ConfigEntry = BadgeProps & { label: string }

const getConfig = (t: TFunction) => ({
  [EstateStatus.Archived]: {
    label: t(`EstateStatusBadge.archived`),
    variant: 'destructive',
    className: 'tw-ml-2',
  } as ConfigEntry,
  [EstateStatus.OnGoing]: {
    label: t(`EstateStatusBadge.onGoing`),
    variant: 'secondary',
  } as ConfigEntry,
  [EstateStatus.MandateSigned]: {
    label: t(`EstateStatusBadge.mandateSigned`),
  } as ConfigEntry,
  [EstateStatus.AOInProgress]: {
    label: t(`EstateStatusBadge.aoInProgress`),
  } as ConfigEntry,
  [EstateStatus.AODone]: {
    label: t(`EstateStatusBadge.aoDone`),
    variant: 'success',
  } as ConfigEntry,
  [EstateStatus.ApplicationAccepted]: {
    label: t(`EstateStatusBadge.applicationAccepted`),
    variant: 'success',
  } as ConfigEntry,
})

type EstateStatusBadgeProps = {
  estate: WebappSimpleEstateFragment
}

export const EstateStatusBadge = ({ estate }: EstateStatusBadgeProps) => {
  const { t } = useTranslation()
  const config = getConfig(t)
  const key = getEstateStatus(estate)
  const { label, ...props } = config[key]

  return <Badge {...props}>{label}</Badge>
}
