import React, { forwardRef, useMemo, useState } from 'react'
import { StarButtonIcon } from './StarButtonIcon'
import { cn } from '@/lib/utils'

export type StarsRatingProps = {
  value?: number
  onChange?(value: number): void
  max?: number
  size?: 'xs' | 'sm' | 'md' | 'lg'
  label?: string
  className?: string
  disabled?: boolean
}

const StarsRating = forwardRef<HTMLDivElement, StarsRatingProps>(
  (
    {
      value = 0,
      onChange,
      max = 5,
      label,
      className,
      size,
      disabled,
      ...props
    },
    ref
  ) => {
    const stars = new Array(max).fill(0).map((_, index) => index + 1)
    const [hovered, setHovered] = useState<number>(0)
    const starSize = useMemo(
      () =>
        ({
          xs: 'tw-h-4 tw-w-4',
          sm: 'tw-h-5 tw-w-5',
          md: 'tw-h-6 tw-w-6',
          lg: 'tw-h-8 tw-w-8',
        })[size ?? 'md'],
      [size]
    )
    return (
      <div
        {...props}
        ref={ref}
        className={cn(
          'tw-flex tw-flex-row tw-gap-4 tw-content-center tw-items-center',
          className
        )}
      >
        <div>
          {stars.map((star) => (
            <StarButtonIcon
              key={star}
              aria-label={star.toString()}
              variant="ghost"
              disabled={disabled || onChange === null}
              className={starSize}
              isActive={value != null && value >= star}
              willBeActive={hovered >= star}
              onClick={() => onChange?.(star)}
              onMouseEnter={() => setHovered(star)}
              onMouseLeave={() => setHovered(0)}
            />
          ))}
        </div>
        {label && <div>{label}</div>}
      </div>
    )
  }
)

StarsRating.displayName = 'StarsRating'
export { StarsRating }
