import { useEmailSchema } from '@/common/hooks/schemas/useEmailSchema'
import { usePasswordSchema } from '@/common/hooks/schemas/usePasswordSchema'
import { usePhoneNumberSchema } from '@/common/hooks/schemas/usePhoneNumberSchema'
import { Seller_Type_Enum, SellerMemberAndItsSellerTypeFragment } from '@gql'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
type UseMemberSigninSchemaProps = {
  member?: SellerMemberAndItsSellerTypeFragment
}
export const useSellerMemberSigninSchema = ({
  member,
}: UseMemberSigninSchemaProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })

  const { schema: passwordSchema } = usePasswordSchema()
  const { schema: emailSchema } = useEmailSchema()
  const { schema: phoneSchema } = usePhoneNumberSchema()

  const jobRequired =
    member?.seller?.type &&
    member?.seller?.type !== Seller_Type_Enum.NaturalPerson

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: emailSchema.required(t('required')),
        ['new-password']: passwordSchema.required(t('required')),
        ['confirm-password']: passwordSchema
          .oneOf([yup.ref('new-password'), undefined], t('passwordMatch'))
          .required(t('required')),
        phone: phoneSchema.required(t('required')),
        job: jobRequired
          ? yup.string().required(t('required'))
          : yup.string().optional(),
      }),
    [t, emailSchema, phoneSchema, passwordSchema, jobRequired]
  )

  return { schema }
}

export type MemberSigninFormValues = yup.InferType<
  ReturnType<typeof useSellerMemberSigninSchema>['schema']
>
