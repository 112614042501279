import React, { useCallback } from 'react'
import { Estate_Type_Enum } from '@gql'
import { useTranslation } from 'react-i18next'
import { Textarea } from '@/components/ui/textarea'
import { OccupationTypeSelect } from '@/common/atoms/OccupationTypeSelect'
import { EquipmentMultiSelect } from '@/common/atoms/EquipmentMultiSelect'
import { PrimaryPurposeSelect } from '@/common/atoms/PrimaryPurposeSelect'
import { OrientationMultiSelect } from '@/common/atoms/OrientationMultiSelect'
import { useFormContext } from 'react-hook-form'
import { Input } from '@/components/ui/input'
import { InputNumber } from '@/components/ui/inputNumber'
import { Switch } from '@/components/ui/switch'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  EstateFeatures,
  featuresVisibilityMatrix,
} from '@almaris/shared/model/estate'
import { EstateUpdateSchema } from '@/estate/hooks/useUpdateEstateSchema'

export type EstateFeaturesFormProps = {
  estateType: Estate_Type_Enum | null
}

export const EstateFeaturesForm = ({ estateType }: EstateFeaturesFormProps) => {
  const { t } = useTranslation()
  const { control, watch, formState } = useFormContext<EstateUpdateSchema>()
  const showFeatureInput = useCallback(
    (feature: keyof EstateFeatures) => {
      return (
        featuresVisibilityMatrix[feature] === true ||
        (estateType != null &&
          Array.isArray(featuresVisibilityMatrix[feature]) &&
          !featuresVisibilityMatrix[feature].includes(estateType))
      )
    },
    [estateType]
  )

  const renderFormField = useCallback(
    (
      feature: keyof EstateFeatures,
      formItemClassname: string,
      children: (field: any) => React.ReactNode,
      invertLabelControl = false
    ) => {
      return (
        showFeatureInput(feature) && (
          <FormField
            control={control}
            name={`features.${feature}`}
            render={({ field }) => (
              <FormItem
                className={`${formItemClassname} ${invertLabelControl ? 'tw-flex tw-flex-row tw-items-center tw-align-middle [&>label]:!tw-mt-0' : ''}`}
              >
                {invertLabelControl ? (
                  <>
                    <FormControl>
                      {children({ ...field, disabled: formState.disabled })}
                    </FormControl>
                    <FormLabel className="tw-ml-2">
                      {t(`EstateFeaturesForm.${feature}`)}
                    </FormLabel>
                  </>
                ) : (
                  <>
                    <FormLabel>{t(`EstateFeaturesForm.${feature}`)}</FormLabel>
                    <FormControl>
                      {children({ ...field, disabled: formState.disabled })}
                    </FormControl>
                  </>
                )}
                <FormMessage />
              </FormItem>
            )}
          />
        )
      )
    },
    [control, formState.disabled, showFeatureInput, t]
  )

  const watchPropertyFreeOfOccupation = watch(
    'features.propertyFreeOfOccupation'
  )

  return (
    <div className="tw-grid tw-grid-cols-6 tw-gap-6 tw-items-end">
      {renderFormField('building', 'tw-col-span-6', (field) => (
        <Input {...field} />
      ))}

      {renderFormField('propertyFloor', 'tw-col-span-2', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}

      {renderFormField('numberOfFloors', 'tw-col-span-2', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}

      {renderFormField('door', 'tw-col-span-2', (field) => (
        <Input {...field} />
      ))}

      {renderFormField('constructionYear', 'tw-col-span-3', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}

      {renderFormField('landSurface', 'tw-col-span-3', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}

      {renderFormField('carrezLawSurface', 'tw-col-span-3', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}

      {renderFormField('habitableSurface', 'tw-col-span-3', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}

      {renderFormField(
        'propertyDescription',
        'tw-col-span-6 tw-row-span-2',
        (field) => (
          <Textarea {...field} />
        )
      )}

      {renderFormField('primaryPurpose', 'tw-col-span-6', (field) => (
        <PrimaryPurposeSelect
          {...field}
          className="!tw-my-0"
          showLabel={false}
        />
      ))}

      {renderFormField('balconyTerraceSurface', 'tw-col-span-3', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}

      {renderFormField('gardenSurface', 'tw-col-span-3', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}

      {renderFormField(
        'furnished',
        'tw-col-span-2',
        (field) => (
          <Switch
            {...field}
            checked={field.value}
            onCheckedChange={field.onChange}
          />
        ),
        true
      )}

      {renderFormField(
        'cellar',
        'tw-col-span-2',
        (field) => (
          <Switch
            {...field}
            checked={field.value}
            onCheckedChange={field.onChange}
          />
        ),
        true
      )}

      {renderFormField(
        'pool',
        'tw-col-span-2',
        (field) => (
          <Switch
            {...field}
            checked={field.value}
            onCheckedChange={field.onChange}
          />
        ),
        true
      )}

      {renderFormField('numberOfRooms', 'tw-col-span-3', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}

      {renderFormField('numberOfBedrooms', 'tw-col-span-3', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}

      {renderFormField('numberOfBathrooms', 'tw-col-span-3', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}

      {renderFormField('numberOfToilets', 'tw-col-span-3', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}

      {renderFormField('numberOfGarages', 'tw-col-span-3', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}

      {renderFormField('numberOfParkings', 'tw-col-span-3', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}

      {renderFormField('orientation', 'tw-col-span-6', (field) => (
        <OrientationMultiSelect
          {...field}
          className="!tw-my-0"
          showLabel={false}
        />
      ))}

      {renderFormField('equipments', 'tw-col-span-6', (field) => (
        <EquipmentMultiSelect
          {...field}
          className="!tw-my-0"
          showLabel={false}
        />
      ))}

      {renderFormField(
        'propertyFreeOfOccupation',
        'tw-col-span-6',
        (field) => (
          <Switch
            {...field}
            checked={field.value}
            onCheckedChange={field.onChange}
          />
        ),
        true
      )}

      {!watchPropertyFreeOfOccupation &&
        renderFormField('occupationType', 'tw-col-span-6', (field) => (
          <OccupationTypeSelect
            {...field}
            className="!tw-my-0"
            showLabel={false}
          />
        ))}

      {renderFormField('accessDetails', 'tw-col-span-6', (field) => (
        <Textarea {...field} />
      ))}

      {renderFormField('propertyTaxAmount', 'tw-col-span-3', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}

      {renderFormField('housingTaxAmount', 'tw-col-span-3', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}

      {renderFormField('annualChargesAmount', 'tw-col-span-6', (field) => (
        <InputNumber {...field} className="tw-w-full" min={0} />
      ))}
    </div>
  )
}
