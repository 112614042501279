import React, { useMemo, useState } from 'react'
import { Title } from '@/common/atoms/Title'
import { useTranslation } from 'react-i18next'
import SellerCreateModal from '../modals/SellerCreateModal'
import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { SellersTable } from '../components/table/SellersTable'
import useSellers from '../hooks/useSellers'
import { Navigate } from 'react-router-dom'
import { useHasuraClaim } from '@nhost/react'
import { useGetEstatesQuery } from '@gql'
import { getEstateStatus } from '@/estate/utils/getEstateStatus'

export default function SellersPage() {
  const { t } = useTranslation()
  const [includeArchived, setIncludeArchived] = useState(false)

  const { sellers: allSellers, loading, error } = useSellers()
  const sellers = useMemo(() => {
    if (includeArchived) {
      return allSellers
    }
    return allSellers?.filter((s) => !s.archived)
  }, [allSellers, includeArchived])

  const sellerMemberId = useHasuraClaim('seller-member-id')
  const isSellerMember = !!sellerMemberId && sellerMemberId !== 'null'

  if (isSellerMember && sellers?.length === 1) {
    return <Navigate to={`/sellers/${sellers[0].id}`} />
  }

  const {
    data: estatesData,
    loading: estatesLoading,
    error: estatesError,
  } = useGetEstatesQuery()

  const sellersEstatesStatusCount = useMemo(() => {
    if (!estatesData?.estate)
      return {} as Record<string, Record<string, number>>
    const initialValues: Record<string, Record<string, number>> = {}
    return estatesData?.estate.reduce((acc, estate) => {
      const estateSellerIds = estate.estate_sellers.map(
        ({ sellerId }) => sellerId
      )
      estateSellerIds.push(estate.sellerId)
      const estateStatus = getEstateStatus(estate)
      estateSellerIds.forEach((sellerId) => {
        if (!acc[sellerId]) acc[sellerId] = {} as Record<string, number>
        if (!acc[sellerId][estateStatus]) acc[sellerId][estateStatus] = 0
        acc[sellerId][estateStatus] += 1
        if (!acc[sellerId]['all']) acc[sellerId]['all'] = 0
        acc[sellerId]['all'] += 1
      })
      return acc
    }, initialValues)
  }, [estatesData?.estate])

  const sellersWithEstatesStatusCounts = useMemo(() => {
    if (!sellers) return []
    return sellers.map((seller) => {
      const sellerId = seller.id
      const sellerEstatesStatusCounts = sellersEstatesStatusCount[sellerId]
      return {
        ...seller,
        estatesStatusCounts: sellerEstatesStatusCounts,
      }
    })
  }, [sellers, sellersEstatesStatusCount])

  if (loading || estatesLoading) return <Loading center active />

  return (
    <div className="tw-p-8">
      <Title>{t('SellersPage.heading')}</Title>
      <div className="tw-flex tw-justify-between tw-items-baseline">
        <h1 className="tw-text-[40px] tw-font-semibold">
          {t('SellersPage.heading')}
        </h1>
        <SellerCreateModal />
      </div>
      <div>
        {error && <TextError error={error} />}
        {estatesError && <TextError error={estatesError} />}
        <SellersTable
          sellers={sellersWithEstatesStatusCounts}
          setIncludeArchived={setIncludeArchived}
        />
      </div>
    </div>
  )
}
