import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { parsePhoneNumber } from 'react-phone-number-input'

export const usePhoneNumberSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })

  const schema = useMemo(
    () =>
      yup.string().test('phone', t('phone'), (value) => {
        if (!value) return true // Allow undefined/empty values, use 'required' if need be

        const phoneNumber = parsePhoneNumber(value)

        if (!phoneNumber?.isPossible()) {
          return false
        }

        return true
      }),
    [t]
  )

  return {
    schema,
  }
}
