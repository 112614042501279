import debounce from 'lodash.debounce'
import { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { usePreventClose } from '../hooks/usePreventClose'

interface Props {
  delay?: number
  onSubmit(): Promise<void>
}

export default function AutoSave({ delay = 3000, onSubmit }: Props) {
  const { preventClose, allowClose } = usePreventClose()

  const {
    watch,
    formState: { isDirty },
  } = useFormContext()

  const submitRef = useRef(onSubmit)
  const preventCloseRef = useRef(false)

  useEffect(() => {
    submitRef.current = onSubmit
  }, [onSubmit])

  // Watch for changes when dirty
  useEffect(() => {
    if (!isDirty) return

    const debouncedSubmit = debounce(async () => {
      await submitRef.current()
      preventCloseRef.current = false
      allowClose()
    }, delay)

    const submit = async () => {
      if (!preventCloseRef.current) {
        preventClose()
        preventCloseRef.current = true
      }
      await debouncedSubmit()
    }

    // Try to submit a first time when form becomes dirty
    submit()

    // Try again when form changes
    const subscription = watch(submit)
    return () => subscription.unsubscribe()
  }, [watch, isDirty])

  return null
}
