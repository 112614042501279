import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useNameSchema } from '../../common/hooks/schemas/useNameSchema'
import { useEmailSchema } from '../../common/hooks/schemas/useEmailSchema'
import { useSlugSchema } from '../../common/hooks/schemas/useSlugSchema'
import { usePhoneNumberSchema } from '../../common/hooks/schemas/usePhoneNumberSchema'

export const useSellerCreateSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })

  const { schema: nameSchema } = useNameSchema()
  const { schema: emailSchema } = useEmailSchema()
  const { schema: slugSchema } = useSlugSchema()
  const { schema: phoneSchema } = usePhoneNumberSchema()

  const schema = useMemo(
    () =>
      yup.object().shape({
        seller: yup.object().shape({
          name: nameSchema.required(t('required')),
          slug: slugSchema.required(t('required')),
        }),
        member: yup.object().shape({
          firstName: nameSchema.required(t('required')),
          lastName: nameSchema.required(t('required')),
          email: emailSchema.required(t('required')),
          phone: phoneSchema.required(t('required')),
        }),
      }),
    [t, nameSchema, emailSchema, slugSchema, phoneSchema]
  )

  return {
    schema,
  }
}

export type SellerCreateFormValues = yup.InferType<
  ReturnType<typeof useSellerCreateSchema>['schema']
>
