import PasswordInput from '@/common/atoms/PasswordInput'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import {
  Seller_Type_Enum,
  useUpdateSellerMemberMutation,
  useUpdateUserMutation,
} from '@gql'
import React, { useMemo } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { nhost } from 'src/nhost'
import {
  MemberSigninFormValues,
  useSellerMemberSigninSchema,
} from '../hooks/useMemberSigninSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import type { SellerMemberAndItsSellerTypeFragment } from '@almaris/backend/src/gql'
import { PhoneInput } from '@/components/ui/phone-input'
// @ts-ignore
import fr from 'react-phone-number-input/locale/fr'

type MemberSigninFormProps = {
  member: SellerMemberAndItsSellerTypeFragment
  onSuccess?: (hasSetPassword: boolean) => void
}

export const MemberSigninForm = ({
  member,
  onSuccess,
}: MemberSigninFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const { schema } = useSellerMemberSigninSchema({ member })

  const [updateMember] = useUpdateSellerMemberMutation()
  const [updateUser] = useUpdateUserMutation()

  // At this point the user should be logged in via the magic link, hence why we can access the user properties
  const defaultValues = useMemo(() => {
    return {
      email: member?.user?.email ?? member?.inviteEmail ?? '',
      ['new-password']: '',
      ['confirm-password']: '',
      phone: member?.user?.phoneNumber ?? member?.invitePhone ?? '',
      job: member?.job ?? '',
    }
  }, [member])

  const form = useForm<MemberSigninFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onSubmit = async ({
    'new-password': password,
    'confirm-password': confirm,
    job,
    phone,
  }: MemberSigninFormValues) => {
    // Set new password to authenticated user
    if (password && confirm) {
      const result = await nhost.auth.changePassword({ newPassword: password })

      if (result.error) {
        console.error(result.error)
        toast({
          variant: 'destructive',
          title: t('MemberSigninForm.toast.changePassword.error.title'),
          description: t(
            'MemberSigninForm.toast.changePassword.error.description'
          ),
        })
        return
      }

      // TODO: We could probably merge those 2 calls on 1 trpc call
      const { data } = await updateMember({
        variables: {
          id: member?.id,
          values: {
            job,
            hasSetPassword: true,
          },
        },
        onCompleted: () => {
          toast({
            variant: 'success',
            description: t('MemberSigninForm.toastSuccess'),
          })
        },
        onError: (error) => {
          console.error(error)
          toast({
            variant: 'destructive',
            description: t('MemberSigninForm.toastError'),
          })
          return
        },
      })

      // If the user changes its phone number at this point, we update it
      if (phone !== member.invitePhone) {
        await updateUser({
          variables: {
            id: member?.userId!,
            values: {
              phoneNumber: phone,
            },
          },
        })
      }

      onSuccess?.(!!data?.update_seller_member_by_pk?.hasSetPassword)
      // Refresh user data
      await nhost.auth.refreshSession()
    }
  }

  const onError = (errors: FieldErrors<MemberSigninFormValues>) => {
    console.error(errors)
  }

  const showJob =
    member?.seller?.type &&
    member?.seller?.type !== Seller_Type_Enum.NaturalPerson

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, onError)}
        className="tw-space-y-5"
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('MemberSigninForm.email')} *</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('MemberSigninForm.emailPlaceholder')}
                  disabled={!member?.user?.email && !member?.inviteEmail}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="new-password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('MemberSigninForm.password')} *</FormLabel>
              <FormControl>
                <PasswordInput
                  id="new-password"
                  required
                  autoComplete="new-password"
                  showRequirements
                  {...field}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="confirm-password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('MemberSigninForm.confirm')} *</FormLabel>
              <FormControl>
                <PasswordInput
                  id="confirm-password"
                  required
                  autoComplete="confirm-password"
                  {...field}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('MemberSigninForm.phone')} *</FormLabel>
              <FormControl>
                <PhoneInput
                  placeholder={t('MemberSigninForm.phonePlaceholder')}
                  defaultCountry="FR"
                  international
                  focusInputOnCountrySelection
                  labels={fr}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {showJob && (
          <FormField
            control={form.control}
            name="job"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('MemberSigninForm.job')} *</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t('MemberSigninForm.jobPlaceholder')}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <Button type="submit" variant="green">
          {t('common.save')}
        </Button>
      </form>
    </Form>
  )
}
