import { useEmailSchema } from '@/common/hooks/schemas/useEmailSchema'
import { useNameSchema } from '@/common/hooks/schemas/useNameSchema'
import { usePhoneNumberSchema } from '@/common/hooks/schemas/usePhoneNumberSchema'
import { useMemo } from 'react'
import * as yup from 'yup'

export const useSellerMemberInfoSchema = () => {
  const { schema: nameSchema } = useNameSchema()
  const { schema: emailSchema } = useEmailSchema()
  const { schema: phoneSchema } = usePhoneNumberSchema()

  const schema = useMemo(
    () =>
      yup.object().shape({
        firstName: nameSchema.required(),
        lastName: nameSchema.required(),
        email: emailSchema.required(),
        phone: phoneSchema.optional(),
        job: yup.string().optional(),
      }),
    [phoneSchema, nameSchema, emailSchema]
  )

  return { schema }
}

export type MemberInfoFormValues = yup.InferType<
  ReturnType<typeof useSellerMemberInfoSchema>['schema']
>
