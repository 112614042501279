import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { SellerMemberFragment } from '@almaris/backend/src/gql'
import { useUpdateSellerMemberMutation, useUpdateUserMutation } from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { nhost } from 'src/nhost'
import {
  MemberInfoFormValues,
  useSellerMemberInfoSchema,
} from '../hooks/useSellerMemberInfoSchema'
import { PhoneInput } from '@/components/ui/phone-input'
// @ts-ignore
import fr from 'react-phone-number-input/locale/fr'
import debounce from 'lodash/debounce'

type MemberInfoFormProps = {
  member?: SellerMemberFragment
}

const DEBOUNCE_TIME = 2000

const SellerMemberInfoForm = ({ member }: MemberInfoFormProps) => {
  const { t } = useTranslation()
  const [isSaving, setIsSaving] = useState(false)

  // Store the initial member data in local state because we want to ignore GraphQL subscriptions
  // TODO: Probably remove subscriptions altogether?
  const [localMember, setLocalMember] = useState(member)

  // Only update localMember on initial mount
  useEffect(() => {
    if (!localMember && member) {
      setLocalMember(member)
    }
  }, [member, localMember])

  const { schema } = useSellerMemberInfoSchema()

  const { toast } = useToast()

  const [updateMember] = useUpdateSellerMemberMutation({
    awaitRefetchQueries: true,
  })

  const [updateUser] = useUpdateUserMutation()

  const defaultValues = useMemo(
    () => ({
      firstName: localMember?.user?.metadata?.firstName ?? '',
      lastName: localMember?.user?.metadata?.lastName ?? '',
      email: localMember?.user?.email ?? '',
      phone: localMember?.user?.phoneNumber ?? '',
      job: localMember?.job ?? '',
    }),
    [localMember]
  )

  const form = useForm<MemberInfoFormValues>({
    disabled: isSaving,
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => form.reset(defaultValues), [defaultValues, form])

  // Validate default values
  useEffect(() => {
    form.trigger()
  }, [form])

  const onSubmit = useCallback(
    async (input: MemberInfoFormValues) => {
      if (!member) return

      // Update user informations
      const displayName = `${input.firstName} ${input.lastName}`
      if (
        displayName !== member.user?.displayName ||
        input.phone !== member.user?.phoneNumber
      ) {
        await updateUser({
          variables: {
            id: member.userId!,
            values: {
              displayName: `${input.firstName} ${input.lastName}`,
              phoneNumber: input.phone,
              metadata: {
                ...member.user?.metadata,
                firstName: input.firstName,
                lastName: input.lastName,
              },
            },
          },
        })
      }

      // Refresh user data
      await nhost.auth.refreshSession()

      await updateMember({
        variables: {
          id: member.id,
          values: {
            job: input.job,
          },
        },
      })

      toast({
        variant: 'success',
        description: t('MemberInfoForm.toast.success'),
      })
    },
    [updateUser, member, t, toast, updateMember]
  )

  // Debounced save function
  const debouncedSave = useMemo(
    () =>
      debounce(async (data: MemberInfoFormValues) => {
        try {
          setIsSaving(true)
          await onSubmit(data)
        } finally {
          setIsSaving(false)
        }
      }, DEBOUNCE_TIME),
    [onSubmit]
  )

  // Watch form changes and trigger autosave
  useEffect(() => {
    const subscription = form.watch(async (data) => {
      await form.trigger()

      // Don't save if the form is invalid
      if (!form.formState.isValid) return

      debouncedSave(data as MemberInfoFormValues)
    })

    return () => {
      subscription.unsubscribe()
      debouncedSave.cancel() // Cancel any pending debounced calls on cleanup
    }
  }, [debouncedSave, defaultValues, form])

  return (
    <Form {...form}>
      <form
        // Remove onSubmit as we're using auto-save
        onSubmit={(e) => e.preventDefault()}
        className="relative"
      >
        {isSaving && (
          <div className="tw-absolute tw-top-0 tw-right-0 tw-text-sm tw-text-muted-foreground">
            Saving...
          </div>
        )}
        <div className="tw-space-y-5">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('MemberInfoForm.firstName')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('MemberInfoForm.lastName')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('MemberInfoForm.email')}</FormLabel>
                <FormControl>
                  <Input readOnly disabled {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('MemberInfoForm.phone')}</FormLabel>
                <FormControl>
                  <PhoneInput
                    international
                    focusInputOnCountrySelection
                    labels={fr}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="job"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('MemberInfoForm.job')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  )
}

export { SellerMemberInfoForm }
