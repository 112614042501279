import { EnumSelect, EnumSelectProps } from '@/enum/EnumSelect'
import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

export enum Primary_Purpose_Enum {
  Residence = 'Residence',
  Business = 'Business',
  EquipmentOfCollectiveInterest = 'EquipmentOfCollectiveInterest',
  AgriculturalAndForestryExploitation = 'AgriculturalAndForestryExploitation',
  OtherActivitiesInTheSecondaryOrTertiarySectors = 'OtherActivitiesInTheSecondaryOrTertiarySectors',
  PublicServices = 'PublicServices',
}

export type PrimaryPurposeSelectProps = Omit<
  EnumSelectProps,
  'entries' | 'label' | 'placeholder'
>
export const PrimaryPurposeSelect = forwardRef<
  HTMLInputElement,
  PrimaryPurposeSelectProps
>((props, ref) => {
  const { t } = useTranslation()
  const name = 'PrimaryPurposeSelect'

  const entries = Object.values(Primary_Purpose_Enum).reduce(
    (acc, type) => {
      acc[type] = t(`${name}.${type}`)
      return acc
    },
    {} as Record<Primary_Purpose_Enum, string>
  )

  return (
    <div>
      <EnumSelect
        {...props}
        entries={entries}
        label={t('PrimaryPurposeSelect.label')}
        placeholder={t('PrimaryPurposeSelect.placeholder')}
        ref={ref}
      />
    </div>
  )
})

PrimaryPurposeSelect.displayName = 'PrimaryPurposeSelect'
