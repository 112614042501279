import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { InputPrice } from '@/components/ui/inputPrice'
import { useToast } from '@/components/ui/use-toast'
import {
  EstateSettingOpeningFormPricesValues,
  useEstateSettingOpeningFormPricesSchema,
} from '@/estate/hooks/useEstateSettingOpeningFormPrices'
import { EstateForSalePageFragment, useUpdateEstateMutation } from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import debounce from 'lodash.debounce'
import React, { useEffect, useMemo } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type EstateSettingOpeningFormPricesProps = {
  estate: Pick<EstateForSalePageFragment, 'id' | 'firstPrice' | 'reservePrice'>
  readOnly?: boolean
}

export const EstateSettingOpeningFormPrices = ({
  estate,
  readOnly,
}: EstateSettingOpeningFormPricesProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [updateEstate] = useUpdateEstateMutation({
    refetchQueries: ['getEstates'],
  })

  const debounceUpdateEstate = debounce(updateEstate, 500)
  const { schema } = useEstateSettingOpeningFormPricesSchema()

  const defaultValues = useMemo(() => {
    const firstPriceRaw = estate.firstPrice ?? 0
    const firstPrice = isNaN(Number(firstPriceRaw)) ? 0 : Number(firstPriceRaw)
    const reservePriceRaw = estate.reservePrice
    const reservePrice = reservePriceRaw
      ? isNaN(Number(reservePriceRaw))
        ? undefined
        : Number(reservePriceRaw)
      : undefined

    return {
      firstPrice,
      reservePrice,
    }
  }, [estate])

  const form = useForm<EstateSettingOpeningFormPricesValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onSubmit = async ({
    firstPrice,
    reservePrice,
  }: EstateSettingOpeningFormPricesValues) => {
    await debounceUpdateEstate({
      variables: {
        id: estate.id,
        values: {
          firstPrice: firstPrice ?? null,
          reservePrice: reservePrice ?? null,
        },
      },
      onCompleted: () => {
        toast({
          variant: 'success',
          description: t('EstateSettingOpeningFormPrices.toast.success'),
        })
      },
      onError: (error) => {
        console.error(error)
        toast({
          variant: 'destructive',
          title: t('EstateSettingOpeningFormDates.updateEstate.error.title'),
          description: t(
            'EstateSettingOpeningFormDates.updateEstate.error.description'
          ),
        })
      },
    })
  }

  const onInvalid = (
    errors: FieldErrors<EstateSettingOpeningFormPricesValues>
  ) => {
    console.log({ errors })
  }

  useEffect(() => {
    const { unsubscribe } = form.watch((value, { type }) => {
      if (type !== 'change') return
      form.handleSubmit(onSubmit, onInvalid)()
    })
    return () => unsubscribe()
  }, [form.watch])

  return (
    <Form {...form}>
      <form className="tw-space-y-6 tw-pt-6">
        <FormField
          control={form.control}
          name="firstPrice"
          render={({ field: { onChange, ...field } }) => {
            const handleValueChange = (value: string | undefined) => {
              onChange(value)
            }
            return (
              <FormItem>
                <FormLabel>
                  {t('EstateSettingOpeningFormPrices.firstPrice')}
                </FormLabel>
                <FormControl>
                  <InputPrice
                    value={field.value}
                    onValueChange={handleValueChange}
                    disabled={readOnly}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        />
        <FormField
          control={form.control}
          name="reservePrice"
          render={({ field: { onChange, ...field } }) => {
            const handleValueChange = (value: string | undefined) => {
              onChange(value ?? null)
            }
            return (
              <FormItem>
                <FormLabel>
                  {t('EstateSettingOpeningFormPrices.reservePrice')}
                </FormLabel>
                <FormControl>
                  <InputPrice
                    value={field.value}
                    onValueChange={handleValueChange}
                    disabled={readOnly}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        />
      </form>
    </Form>
  )
}
