import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import React, { forwardRef } from 'react'
import { ControllerRenderProps } from 'react-hook-form'

export type EnumSelectProps = ControllerRenderProps<any> & {
  className?: string
  entries: Object
  label?: string
  placeholder?: string
  showLabel?: boolean
  required?: boolean
}
export const EnumSelect = forwardRef<HTMLInputElement, EnumSelectProps>(
  (
    {
      onChange,
      value,
      className,
      entries,
      label,
      showLabel = true,
      placeholder,
      disabled,
      required,
    },
    ref
  ) => {
    return (
      <FormItem className={className}>
        {showLabel && label && (
          <FormLabel>
            {label} {required ? '*' : ''}
          </FormLabel>
        )}
        <Select onValueChange={onChange} value={value} disabled={disabled}>
          <FormControl>
            <SelectTrigger className="tw-w-full">
              <SelectValue ref={ref} placeholder={placeholder} />
            </SelectTrigger>
          </FormControl>
          <SelectContent>
            {Object.entries(entries).map(([key, value]) => (
              <SelectItem value={key} key={key}>
                {value}
              </SelectItem>
            ))}
          </SelectContent>
          <FormMessage />
        </Select>
      </FormItem>
    )
  }
)

EnumSelect.displayName = 'EnumSelect'
