import { Card } from '@/components/ui/card'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from '@/components/ui/collapsible'
import React from 'react'

export const EstateEditFormCard = ({
  title,
  children,
  defaultOpen = true,
}: {
  title: string
  children: React.ReactNode
  defaultOpen?: boolean
}) => {
  return (
    <Collapsible defaultOpen={defaultOpen}>
      <CollapsibleTrigger className="tw-w-full tw-group">
        <div className="tw-flex tw-items-center tw-justify-between tw-group-hover:tw-bg-red-500">
          <h2 className="tw-text-2xl tw-font-semibold">{title}</h2>
          <ChevronDownIcon
            className="tw-transition-transform tw-duration-200 group-data-[state=open]:tw-rotate-0 group-data-[state=closed]:tw-rotate-180"
            width={24}
            height={24}
          />
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <Card className="tw-mt-4 tw-mb-6 tw-p-4">{children}</Card>
      </CollapsibleContent>
    </Collapsible>
  )
}
