import PasswordInput from '@/common/atoms/PasswordInput'
import { Title } from '@/common/atoms/Title'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSignInEmailPassword } from '@nhost/react'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link as ReachLink } from 'react-router-dom'
import { LoginFormValues, useLoginSchema } from '../hooks/useLoginSchema'
import { nhost } from 'src/nhost'

interface Props {
  defaultEmail?: string
}

const LoginForm = ({ defaultEmail }: Props) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const { schema } = useLoginSchema()

  const { signInEmailPassword, isLoading } = useSignInEmailPassword()

  const onSubmit = async (values: LoginFormValues) => {
    try {
      const { needsEmailVerification, isSuccess, error } =
        await signInEmailPassword(values.email, values['current-password'])
      if (isSuccess) {
        await nhost.auth.refreshSession()

        const sellerClaim = nhost.auth.getHasuraClaim('seller-member-id')
        const agencyMemberClaim = nhost.auth.getHasuraClaim('agency-member-id')

        // TODO: User Nhost roles instead?
        if (sellerClaim === 'null' && agencyMemberClaim === 'null') {
          toast({
            variant: 'destructive',
            title: t('LoginForm.signInEmailPassword.public.title'),
            description: t('LoginForm.signInEmailPassword.public.description'),
          })

          await nhost.auth.signOut()
          return
        }

        toast({
          title: t('LoginForm.signInEmailPassword.success.title'),
          description: t('LoginForm.signInEmailPassword.success.description'),
          variant: 'success',
        })
      }
      if (error) {
        console.error(error)

        const title = t('LoginForm.signInEmailPassword.error.title')

        // Disabling type checking because dynamic keys are causing issues
        // @ts-ignore
        const description = t([
          `nhost.auth.error.${error.error}`,
          'LoginForm.signInEmailPassword.error.description',
        ])

        toast({
          variant: 'destructive',
          title: title as string,
          description: description as string,
        })
      }
      if (needsEmailVerification) {
        toast({
          title: t(
            'LoginForm.signInEmailPassword.needsEmailVerification.title'
          ),
          description: t(
            'LoginForm.signInEmailPassword.needsEmailVerification.description'
          ),
        })
      }
    } catch (error: any) {
      console.error(error)
      toast({
        variant: 'destructive',
        title: t('LoginForm.signInEmailPassword.error.title'),
        description: t('LoginForm.signInEmailPassword.error.description'),
      })
    }
  }

  const form = useForm<LoginFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: defaultEmail ?? '',
      ['current-password']: '',
    },
  })

  const email = form.watch('email')

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="tw-space-y-5">
        <Title>{t('LoginForm.heading')}</Title>

        <div className="tw-space-y-2 tw-mb-5">
          <h1 className="tw-text-2xl tw-font-bold">{t('LoginForm.heading')}</h1>
          <p className="tw-text-sm">{t('LoginForm.description')}</p>
        </div>

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('LoginForm.email')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="current-password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('LoginForm.password')}</FormLabel>
              <FormControl>
                <PasswordInput
                  id="current-password"
                  required
                  autoComplete="current-password"
                  {...field}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <ReachLink
          to={`/forgot-password${email ? `?email=${email}` : ''}`}
          className="tw-block tw-text-sm tw-text-gray-500"
        >
          {t('LoginForm.resetPassword')}
        </ReachLink>
        <Button variant="green" type="submit" disabled={isLoading}>
          {t('LoginForm.submit')}
        </Button>
      </form>
    </Form>
  )
}

export { LoginForm }
