import useAgencyMember from '@/agencyMember/hooks/useAgencyMember'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { useUpdateUserMutation } from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import { User } from '@nhost/react'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { nhost } from 'src/nhost'
import {
  UserInfoFormValues,
  useUserInfoSchema,
} from '../hooks/useUserInfoSchema'

type UserInfoFormProps = {
  user: User | null
}

export const UserInfoForm = ({ user }: UserInfoFormProps) => {
  const { t } = useTranslation()
  const { schema } = useUserInfoSchema()
  const { toast } = useToast()

  const agencyMember = useAgencyMember()
  const [updateUser] = useUpdateUserMutation()

  const defaultValues = useMemo(
    () => ({
      firstName: agencyMember?.user?.metadata.firstName ?? '',
      lastName: agencyMember?.user?.metadata.lastName ?? '',
      email: agencyMember?.user?.email!,
    }),
    [agencyMember]
  )

  const form = useForm<UserInfoFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => form.reset(defaultValues), [defaultValues])

  const onSubmit = async (input: UserInfoFormValues) => {
    if (!user) return

    // Update display name
    if (input.firstName + ' ' + input.lastName !== user.displayName) {
      await updateUser({
        variables: {
          id: user.id,
          values: {
            displayName: input.firstName + ' ' + input.lastName || '',
            metadata: {
              ...agencyMember?.user?.metadata,
              firstName: input.firstName,
              lastName: input.lastName,
            },
          },
        },
      })
    }

    // Refresh user data
    await nhost.auth.refreshSession()

    if (!agencyMember?.id) {
      toast({
        variant: 'destructive',
        description: t('UserInfoForm.toast.error'),
      })
      return
    }

    toast({
      variant: 'success',
      description: t('UserInfoForm.toast.success'),
    })
  }

  // TODO: FOR UPDATE on blur (and on enter ??, in utils ??)
  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    // Assuming the name of the input matches the field name in the form
    const fieldName = event.target.name as keyof UserInfoFormValues

    const currentValue = event.target.value
    const initialValue = defaultValues[fieldName]

    // Only submit if the current value is different from the initial value
    if (currentValue !== initialValue) {
      form.handleSubmit(onSubmit)()
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="tw-space-y-5">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('UserInfoForm.firstName')}</FormLabel>
                <FormControl>
                  <Input {...field} onBlur={onBlurHandler} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('UserInfoForm.lastName')}</FormLabel>
                <FormControl>
                  <Input {...field} onBlur={onBlurHandler} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('UserInfoForm.email')}</FormLabel>
                <FormControl>
                  <Input readOnly disabled {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  )
}
