import { FilePreview } from '@/common/atoms/FilePreview'
import {
  BuyerMemberFolderFragment,
  Civility_Enum,
  Seller_Legal_Status_Enum,
  Seller_Marital_Status_Enum,
  Seller_Tax_System_Enum,
  Seller_Type_Enum,
} from '@gql'
import { formatDate } from '@almaris/shared/utils/dates'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

type BuyerMemberFolderEntryProps = {
  title: string
  value: ReactNode
}
type BuyerMemberFolderEntries = (BuyerMemberFolderEntryProps & {
  key: keyof BuyerMemberFolderFragment
})[]

const BuyerMemberFolderEntry = ({
  title,
  value,
}: BuyerMemberFolderEntryProps) => {
  return (
    <div className="tw-w-full tw-flex tw-flex-row tw-justify-between tw-items-center tw-gap-4">
      <p className="tw-text-stone-400 tw-text-sm">{title}</p>
      {typeof value === 'string' ? (
        <p className="tw-text-sm tw-font-medium tw-text-zinc-900">{value}</p>
      ) : (
        value
      )}
    </div>
  )
}

export type BuyerMemberFolderProps = BuyerMemberFolderFragment

export const BuyerMemberFolder = ({
  emailAddress,
  individualOrLegalEntity,
  legalForm,
  taxRegimeCompany,
  companyName,
  sirenNumber,
  subjectToVat,
  vatNumber,
  registeredOfficeAddress,
  kbisFileId,
  civility,
  firstName,
  lastName,
  birthName,
  address,
  mobilePhone,
  homePhone,
  workPhone,
  birthDate,
  birthPlace,
  nationality,
  fluentInFrench,
  languagesSpoken,
  identityDocumentFileId,
  maritalStatus,
  comment,
  countryOfTaxResidence,
}: BuyerMemberFolderProps) => {
  const { t } = useTranslation()

  const formattedBirthDate = formatDate(birthDate)

  const entries: BuyerMemberFolderEntries = [
    {
      key: 'emailAddress',
      title: t('BuyerMemberFolder.emailAddress'),
      value: emailAddress,
    },
    {
      key: 'individualOrLegalEntity',
      title: t('BuyerMemberFolder.individualOrLegalEntity'),
      value:
        individualOrLegalEntity &&
        t(`SellerTypeEnum.${individualOrLegalEntity as Seller_Type_Enum}`),
    },
    {
      key: 'legalForm',
      title: t('BuyerMemberFolder.legalForm'),
      value:
        legalForm &&
        t(`SellerLegalStatusEnum.${legalForm as Seller_Legal_Status_Enum}`),
    },
    {
      key: 'taxRegimeCompany',
      title: t('BuyerMemberFolder.taxRegimeCompany'),
      value:
        taxRegimeCompany &&
        t(`SellerTaxSystemEnum.${taxRegimeCompany as Seller_Tax_System_Enum}`),
    },
    {
      key: 'companyName',
      title: t('BuyerMemberFolder.companyName'),
      value: companyName,
    },
    {
      key: 'sirenNumber',
      title: t('BuyerMemberFolder.sirenNumber'),
      value: sirenNumber,
    },
    {
      key: 'subjectToVat',
      title: t('BuyerMemberFolder.subjectToVat'),
      value:
        individualOrLegalEntity &&
        (individualOrLegalEntity as Seller_Type_Enum) ==
          Seller_Type_Enum.LegalEntity
          ? subjectToVat
            ? t('common.yes')
            : t('common.no')
          : null,
    },
    {
      key: 'vatNumber',
      title: t('BuyerMemberFolder.vatNumber'),
      value: vatNumber,
    },
    {
      key: 'registeredOfficeAddress',
      title: t('BuyerMemberFolder.registeredOfficeAddress'),
      value: registeredOfficeAddress,
    },
    {
      key: 'kbisFileId',
      title: t('BuyerMemberFolder.kbisFileId'),
      value: kbisFileId && <FilePreview fileId={kbisFileId} />,
    },
    {
      key: 'civility',
      title: t('BuyerMemberFolder.civility'),
      value: civility && t(`CivilityEnum.${civility as Civility_Enum}`),
    },
    {
      key: 'firstName',
      title: t('BuyerMemberFolder.firstName'),
      value: firstName,
    },
    {
      key: 'lastName',
      title: t('BuyerMemberFolder.lastName'),
      value: lastName,
    },
    {
      key: 'birthName',
      title: t('BuyerMemberFolder.birthName'),
      value: birthName,
    },
    {
      key: 'address',
      title: t('BuyerMemberFolder.address'),
      value: address,
    },
    {
      key: 'mobilePhone',
      title: t('BuyerMemberFolder.mobilePhone'),
      value: mobilePhone,
    },
    {
      key: 'homePhone',
      title: t('BuyerMemberFolder.homePhone'),
      value: homePhone,
    },
    {
      key: 'workPhone',
      title: t('BuyerMemberFolder.workPhone'),
      value: workPhone,
    },
    {
      key: 'birthDate',
      title: t('BuyerMemberFolder.birthDate'),
      value: formattedBirthDate,
    },
    {
      key: 'birthPlace',
      title: t('BuyerMemberFolder.birthPlace'),
      value: birthPlace,
    },
    {
      key: 'nationality',
      title: t('BuyerMemberFolder.nationality'),
      // @ts-ignore - ignore translation typing for countries
      value: nationality && t(`countries.${nationality}`).toString(),
    },
    {
      key: 'fluentInFrench',
      title: t('BuyerMemberFolder.fluentInFrench'),
      value: fluentInFrench ? t('common.yes') : t('common.no'),
    },
    {
      key: 'languagesSpoken',
      title: t('BuyerMemberFolder.languagesSpoken'),
      value: languagesSpoken,
    },
    {
      key: 'identityDocumentFileId',
      title: t('BuyerMemberFolder.identityDocumentFileId'),
      value: identityDocumentFileId && (
        <FilePreview fileId={identityDocumentFileId} />
      ),
    },
    {
      key: 'maritalStatus',
      title: t('BuyerMemberFolder.maritalStatus'),
      value:
        maritalStatus &&
        t(
          `SellerMaritalStatusEnum.${maritalStatus as Seller_Marital_Status_Enum}`
        ),
    },
    {
      key: 'comment',
      title: t('BuyerMemberFolder.comment'),
      value: comment,
    },
    {
      key: 'countryOfTaxResidence',
      title: t('BuyerMemberFolder.countryOfTaxResidence'),
      value:
        countryOfTaxResidence &&
        // @ts-ignore - ignore translation typing for countries
        t(`countries.${countryOfTaxResidence}`).toString(),
    },
  ]

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      {entries.map(({ key, title, value }) => {
        if (!value) return null
        return <BuyerMemberFolderEntry key={key} title={title} value={value} />
      })}
    </div>
  )
}
