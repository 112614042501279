import { Estate_Type_Enum } from '@gql'
import { useMemo } from 'react'
import * as yup from 'yup'
import { Equipment_Enum } from '@/common/atoms/EquipmentMultiSelect'
import { Occupation_Type_Enum } from '@/common/atoms/OccupationTypeSelect'
import { Orientation_Enum } from '@/common/atoms/OrientationMultiSelect'
import { Primary_Purpose_Enum } from '@/common/atoms/PrimaryPurposeSelect'

const positiveNumberSchema = yup
  .number()
  .optional()
  .min(0, { key: 'Forms.errors.min_number', values: { min: 0 } })
  .transform((_, value) => {
    if (value === '' || value === null || value === undefined) return undefined
    const num = Number(value)
    return isNaN(num) ? undefined : num
  })

const estateConditionSchema = yup
  .number()
  .min(0, { key: 'Forms.errors.min_number', values: { min: 0 } })
  .max(3, { key: 'Forms.errors.max_number', values: { max: 3 } })
  .optional()
  .transform((_, value) => {
    if (value === '' || value === null || value === undefined || isNaN(value))
      return undefined

    return Number(value)
  })
  .test('isNumber', { key: 'Forms.errors.number' }, (value) => {
    if (value === null || value === undefined) return true
    return !isNaN(Number(value))
  })

const estateServiceSchema = estateConditionSchema.max(4)

const nullableStringSchema = yup
  .string()
  .optional()
  .nullable()
  .transform((value: any, originalValue: any) => {
    return originalValue === '' ? null : value
  })

export const useUpdateEstateSchema = () => {
  const schema = useMemo(
    () =>
      yup.object().shape({
        name: nullableStringSchema,
        address: yup.string().optional(),
        city: yup.string().optional(),
        zipCode: yup.string().optional(),
        publicationTitle: nullableStringSchema,
        publicationDescription: nullableStringSchema,
        publicationMention: nullableStringSchema,
        canConnectedBuyerSeeAddress: yup.boolean(),
        type: yup
          .mixed<Estate_Type_Enum>()
          .oneOf(Object.values(Estate_Type_Enum))
          .nullable(),
        features: yup
          .object()
          .shape({
            building: yup.string(),
            propertyFloor: positiveNumberSchema,
            door: yup.string(),
            numberOfFloors: positiveNumberSchema,
            constructionYear: positiveNumberSchema,
            carrezLawSurface: positiveNumberSchema,
            habitableSurface: positiveNumberSchema,
            propertyDescription: yup.string(),
            marketingDescription: yup.string(),
            primaryPurpose: yup
              .mixed<Primary_Purpose_Enum>()
              .oneOf(Object.values(Primary_Purpose_Enum))
              .nullable(),
            landSurface: positiveNumberSchema,
            balconyTerraceSurface: positiveNumberSchema,
            gardenSurface: positiveNumberSchema,
            furnished: yup.boolean(),
            cellar: yup.boolean(),
            pool: yup.boolean(),
            numberOfRooms: positiveNumberSchema,
            numberOfBedrooms: positiveNumberSchema,
            numberOfToilets: positiveNumberSchema,
            numberOfBathrooms: positiveNumberSchema,
            numberOfGarages: positiveNumberSchema,
            numberOfParkings: positiveNumberSchema,
            orientation: yup
              .array()
              .of(
                yup
                  .mixed<Orientation_Enum>()
                  .oneOf(Object.values(Orientation_Enum))
              )
              .nullable(),
            equipments: yup
              .array()
              .of(
                yup.mixed<Equipment_Enum>().oneOf(Object.values(Equipment_Enum))
              )
              .nullable(),
            propertyFreeOfOccupation: yup.boolean(),
            occupationType: yup
              .mixed<Occupation_Type_Enum>()
              .oneOf(Object.values(Occupation_Type_Enum))
              .nullable(),
            accessDetails: yup.string(),
            propertyTaxAmount: positiveNumberSchema,
            housingTaxAmount: positiveNumberSchema,
            annualChargesAmount: positiveNumberSchema,
          })
          .nullable(),
        servicesAndConditions: yup
          .object()
          .shape({
            overallCondition: estateConditionSchema,
            bathroomShowerServices: estateServiceSchema,
            bathroomShowerCondition: estateConditionSchema,
            kitchenServices: estateServiceSchema,
            kitchenCondition: estateConditionSchema,
            flooringServices: estateServiceSchema,
            flooringCondition: estateConditionSchema,
            windowServices: estateServiceSchema,
            windowCondition: estateConditionSchema,
            masonryServices: estateServiceSchema,
            masonryCondition: estateConditionSchema,
            roofingServices: estateServiceSchema,
            roofingCondition: estateConditionSchema,
          })
          .nullable(),
        coownership: yup
          .object()
          .shape({
            isCoowned: yup.boolean(),
            syndicExtranetURL: yup
              .string()
              .url({ key: 'Forms.errors.url' })
              .optional()
              .nullable(),
            syndicExtranetLogin: yup.string().optional().nullable(),
            syndicExtranetPassword: yup.string().optional().nullable(),
            totalLotsNumber: yup.number().optional().nullable(),
            ongoingProcedures: yup.boolean().optional().nullable(),
          })
          .nullable(),
      }),
    []
  )

  return { schema }
}

export type EstateUpdateSchema = yup.InferType<
  ReturnType<typeof useUpdateEstateSchema>['schema']
>
